import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from "jspdf-autotable";
import { useEffect, useMemo, useState } from 'react';
import { Badge, Button, Modal } from 'react-bootstrap';
import * as Feather from 'react-feather';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Card from '../components/custom/Card';
import Main from '../components/custom/Main';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { LocalFormatDate, rupeeFormat } from '../config/functions';
import '../styles/StudentFees.css';
import { StudentFeesDetails, StudentFeesPayment, StudentFeesType } from './Interface';
import { useSelector } from 'react-redux';
import { StoreState } from '../redux/reducers';
import { AuthUserType } from '../redux/actions/authUserActions';
import Select, { SingleValue } from 'react-select';
import { amountInWords } from '../components/common/functions';

interface FeesAmounts {
    [key: string]: number;
}

interface StudentRegNo {
    id: number;
    registerNumber: string
}

const StudentFeesCancelByRegNo = () => {
    const { id } = useParams();
    const [studentRegNo, setStudentRegNo] = useState<StudentRegNo[]>([]);
    const [studentFeesList, setStudentFeesList] = useState<StudentFeesType[]>([]);
    const [studentMiscFeesList, setStudentMiscFeesList] = useState<StudentFeesType[]>([]);
    const [studentFeesDetails, setStudentFeesDetails] = useState<StudentFeesDetails[]>([]);
    const authUser = useSelector<StoreState, AuthUserType>((state) => state.authUser);
    const [loading, setLoading] = useState(false);
    const [registerNo, setRegisterNo] = useState<string>('');

    const [title, setTitle] = useState('');
    const [academicPeriod, setAcademicPeriod] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ids, setId] = useState<number>(0);
    const [billNo, setBillNo] = useState<number>(0);
    const [feesType, setFeesType] = useState<string>('year');
    const [academicYear, setAcademicYear] = useState<string>('');
    const [amount, setAmount] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [particulars, setParticulars] = useState<string>('');
    const [amountError, setAmountError] = useState<string>('');
    const [paymentMode, setPaymentMode] = useState<'cash' | 'bank'>('cash');
    const [feesAmounts, setFeesAmounts] = useState<FeesAmounts>({});

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDownloadButton, setShowDownloadButton] = useState(false);

    //for bill
    const [selectedBill, setSelectedBill] = useState<{
        billNo: number;
        studentId: number;
    } | null>(null);
    const [showBillModal, setShowBillModal] = useState(false);
    const [billDetails, setBillDetails] = useState<StudentFeesPayment[]>([]);

    const fetchStuRegNo = () => {
        axios.get<{ data: StudentRegNo[] }>(`${apiUrl}/student/getAllStuRegisterNo/`)
            .then((response) => {
                setStudentRegNo(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        fetchStuRegNo();
    }, []);

    const fetchStudentFeesList = (registerNo: string) => {
        axios.get<{ data: StudentFeesType[] }>(`${apiUrl}/student/studentFees/?registerNumber=${registerNo}`)
            .then((response) => {
                setStudentFeesList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    const fetchFeesDetails = async (registerNumber: string) => {
        console.log(registerNumber);
        if (registerNumber) {
            try {
                const response = await axios.get<{ data: StudentFeesDetails[] }>(`${apiUrl}/studentFee/stuPaymentByRegNo/?registerNumber=${registerNumber}`);
                const data = response.data.data;
                console.log(data);
                setStudentFeesDetails(data);
                setTitle(title);
                setAcademicPeriod(academicPeriod);
                setFeesType(feesType);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleBillClick = async (billNo: number, studentId: number) => {
        try {
            setSelectedBill({ billNo, studentId });
            const response = await axios.get<{ data: StudentFeesPayment[] }>(
                `${apiUrl}/studentFee/paymentPDF/?id=${studentId}&billNo=${billNo}`
            );
            if (response.status === 200 && response.data.data) {
                setBillDetails(response.data.data);
                setShowBillModal(true);
            }
        } catch (error) {
            console.error("Error fetching bill details:", error);
            toast.error("Failed to fetch bill details", { position: "top-right" });
        }
    };

    const selectOptions = useMemo(() => studentRegNo.map(student => ({
        value: student.registerNumber,
        label: student.registerNumber
    })), [studentRegNo]);

    const generatePDF = (studentFeesList: StudentFeesPayment[], copyName: string) => {
        const MAX_ROWS_PER_PAGE = 16;
        const allParticulars = studentFeesList;
        const totalPages = Math.ceil(allParticulars.length / MAX_ROWS_PER_PAGE);
        const student = studentFeesList.length > 0 ? studentFeesList[0] : null;
        const doc = new jsPDF();

        for (let page = 0; page < totalPages; page++) {
            if (page > 0) {
                doc.addPage();
            }

            const startIndex = page * MAX_ROWS_PER_PAGE;
            const endIndex = Math.min(startIndex + MAX_ROWS_PER_PAGE, allParticulars.length);
            const imgPath = studentFeesList[0]?.photo;
            const title = student?.branchName || "Unknown Branch";

            doc.setFontSize(10);
            doc.setFont("times", "bold");
            doc.text(copyName ? `[${copyName}]` : "", doc.internal.pageSize.getWidth() - 10, 10, { align: 'right' });
            doc.setFont("Arial", "bold");

            doc.setFontSize(11);
            const pageWidth = doc.internal.pageSize.getWidth();
            const titleX = (pageWidth) / 2;

            doc.text(title, titleX + 5, 14, { align: 'center', maxWidth: 80 });
            doc.addImage(imgPath, 'JPEG', titleX - 70, 3, 30, 30);

            doc.setFontSize(10);
            let yPos = 20;

            doc.setFont("Arial", "normal");
            const approvedText = student?.approvedBy ? `(${student?.approvedBy.trimEnd()})` : "";
            if (approvedText) {
                const textWidth1 = doc.getTextWidth(approvedText);
                const textX1 = (pageWidth - textWidth1) / 2;
                doc.text(approvedText, textX1 + 7, yPos);
                yPos += 5;
            }

            const recognisedText = student?.recognisedBy ? `(${student?.recognisedBy.trimEnd()})` : "";
            if (recognisedText) {
                const textWidth2 = doc.getTextWidth(recognisedText);
                const textX2 = (pageWidth - textWidth2) / 2;
                doc.text(recognisedText, textX2 + 7, yPos);
                yPos += 5;
            }

            const sponsoredText = student?.sponsoredBy ? `(${student?.sponsoredBy.trimEnd()})` : "";
            if (sponsoredText) {
                const textWidth3 = doc.getTextWidth(sponsoredText);
                const textX3 = (pageWidth - textWidth3) / 2;
                doc.text(sponsoredText, textX3 + 7, yPos);
                yPos += 5;
            }

            const universityText = student?.affiliatedUniversity ? `(${student?.affiliatedUniversity.trimEnd()})` : "";
            if (universityText) {
                const textWidth4 = doc.getTextWidth(universityText);
                const textX4 = (pageWidth - textWidth4) / 2;
                doc.text(universityText, textX4 + 7, yPos);
                yPos += 8;
            }

            const placeText = student?.place ? `${student?.place.trimEnd()}` : "";
            if (placeText) {
                const textWidth5 = doc.getTextWidth(placeText);
                const textX5 = (pageWidth - textWidth5) / 2;
                doc.text(placeText, textX5 + 7, yPos);
                yPos += 8;
            }

            doc.line(10, yPos - 2, 90, yPos - 2);
            doc.line(120, yPos - 2, 200, yPos - 2);

            doc.setFontSize(14);
            const textWidth6 = doc.getTextWidth("Fees Receipt");
            const textX6 = (pageWidth - textWidth6) / 2;
            doc.text("Fees Receipt", textX6, yPos);
            yPos += 10;

            const headerRows1: any[] = [
                [
                    { content: "Name:", styles: { halign: "left", fontStyle: 'bold' } },
                    { content: student?.studentName ? student.studentName.charAt(0).toUpperCase() + student.studentName.slice(1) : '', styles: { halign: "left", cellWidth: 95 } },
                    { content: "Bill No:", styles: { halign: "left", fontStyle: 'bold' } },
                    { content: student?.billNo || '', styles: { halign: "left" } },
                ],
                [
                    { content: "Class :", styles: { halign: "left", fontStyle: 'bold' } },
                    { content: student?.courseName ? student.courseName.charAt(0).toUpperCase() + student.courseName.slice(1) : '', styles: { halign: "left", cellWidth: 95 } },
                    { content: "Date & Time:", styles: { halign: "left", fontStyle: 'bold' } },
                    { content: `${LocalFormatDate(student?.date || "default date")} ${student?.time || ''}`, styles: { halign: "left" } },
                ],
                [
                    { content: "Reg.No:", styles: { halign: "left", fontStyle: 'bold', } },
                    { content: student?.registerNumber || '', styles: { halign: "left", cellWidth: 95 } },
                    { content: "Mode:", styles: { halign: "left", fontStyle: 'bold' } },
                    { content: student?.paymentMode ? student.paymentMode.charAt(0).toUpperCase() + student.paymentMode.slice(1) : '', styles: { halign: "left" } },
                ],
            ];

            autoTable(doc, {
                startY: yPos,
                theme: 'plain',
                styles: { fontSize: 10, lineColor: [255, 255, 255], lineWidth: 0.3 },
                head: [],
                body: headerRows1,
                tableLineColor: [0, 0, 0],
                tableLineWidth: 0.3,
            });

            const particularsData = Array.from({ length: MAX_ROWS_PER_PAGE }, (_, index) => {
                const actualIndex = startIndex + index;
                if (actualIndex < endIndex) {
                    const field = allParticulars[actualIndex];
                    return [
                        { content: (actualIndex + 1).toString() },
                        { content: `${field.academicPeriod} ${field.feesType === 'semester' ? 'sem' : field.feesType}` },
                        field.title,
                        { content: `${field.amount.toFixed(2)}` }
                    ];
                } else {
                    return [
                        { content: '' },
                        { content: '' },
                        '',
                        { content: '' }
                    ];
                }
            });

            const pageTotalAmount = allParticulars
                .slice(startIndex, endIndex)
                .reduce((sum, field) => sum + Number(field.amount), 0);


            const footerRows3: any[] = [
                [
                    { content: '', styles: { cellWidth: 18 } },
                    { content: '', styles: { cellWidth: 20 } },
                    { content: 'Total', styles: { halign: "right" } },
                    { content: `Rs. ${pageTotalAmount.toFixed(2)}`, styles: { halign: "right" } },
                ],
                [
                    { content: '', styles: { cellWidth: 18 } },
                    { content: '', styles: { cellWidth: 20 } },
                    { content: `Amount in words: ${amountInWords(Math.abs(pageTotalAmount))} Refund`, colSpan: 2, styles: { halign: "right", fontSize: 10, fontStyle: 'italic' } }
                ]
            ];

            autoTable(doc, {
                startY: (doc as any).lastAutoTable.finalY,
                theme: 'plain',
                styles: {
                    fontSize: 10,
                    lineColor: [255, 255, 255],
                    lineWidth: 0
                },
                head: [
                    [
                        { content: 'S.No', styles: { halign: 'center', fontStyle: 'bold', lineColor: [0, 0, 0] } },
                        { content: 'Term', styles: { halign: 'center', fontStyle: 'bold', lineColor: [0, 0, 0] } },
                        { content: 'Particulars', styles: { halign: 'center', fontStyle: 'bold', lineColor: [0, 0, 0] } },
                        { content: 'Amount', styles: { halign: 'right', fontStyle: 'bold', lineColor: [0, 0, 0] } }
                    ]
                ],
                body: particularsData,
                foot: footerRows3,
                headStyles: {
                    lineWidth: 0.3,
                    lineColor: [0, 0, 0],
                    halign: 'center',
                    valign: 'middle',
                    fontSize: 10,
                    cellPadding: 5,
                    fillColor: [255, 255, 255],
                },
                bodyStyles: {
                    fontSize: 10,
                    cellPadding: 2,
                    minCellHeight: 3,
                    valign: 'middle',
                    lineWidth: 0,
                    lineColor: [255, 255, 255],
                },
                columnStyles: {
                    0: { halign: 'center', cellWidth: 18, lineColor: [0, 0, 0] },
                    1: { halign: 'center', cellWidth: 20, lineColor: [0, 0, 0] },
                    2: { cellWidth: 108.6, halign: 'left', lineColor: [0, 0, 0] },
                    3: { halign: 'right', cellWidth: 35, lineColor: [0, 0, 0] }
                },
                footStyles: {
                    lineWidth: 0.3,
                    lineColor: [0, 0, 0],
                    fontSize: 10,
                    fontStyle: 'bold',
                    cellPadding: 2,
                    halign: 'right',
                    valign: 'middle',
                },
                didDrawCell: (data) => {
                    doc.setLineWidth(0.3);
                    doc.setDrawColor(0, 0, 0);
                    doc.line(
                        data.cell.x + data.cell.width,
                        data.cell.y,
                        data.cell.x + data.cell.width,
                        data.cell.y + data.cell.height
                    );
                    if (data.column.index === 0) {
                        doc.line(
                            data.cell.x,
                            data.cell.y,
                            data.cell.x,
                            data.cell.y + data.cell.height
                        );
                    }
                },
            });

            doc.setFontSize(10);
            // doc.setFont("times", "bold");
            // doc.text(`Amount in words: ${amountInWords(pageTotalAmount)} Only`, 10, (doc as any).lastAutoTable.finalY + 5);
            // doc.setFont("times", "normal");

            doc.line(10, (doc as any).lastAutoTable.finalY + 8, 200, (doc as any).lastAutoTable.finalY + 8);

            doc.setFont("times", "bold");
            doc.text(`Billed By: ${student?.createdBy}`, 10, (doc as any).lastAutoTable.finalY + 16);
            doc.setFont("times", "normal");

            doc.setFontSize(10);
            doc.setFont("times", "italic");
            doc.text("Fees once paid will not be refunded under any circumstances.", 10, (doc as any).lastAutoTable.finalY + 21);
            doc.setFontSize(12);
            doc.setFont("times");
            doc.text("Signature of Receiving Officer", 150, (doc as any).lastAutoTable.finalY + 26);

            doc.setFontSize(8);
            doc.setFont("Arial", "bold");
        }

        const pageCount = (doc as any).internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            doc.setFontSize(10);
            doc.setFont("times", "italic");
            doc.text("This is computer generated bill", 10, pageHeight - 10, { align: 'left' });
            doc.setFont("times", "bold");
            doc.text(`Page ${i} of ${pageCount}`, pageWidth - 10, pageHeight - 10, { align: 'right' });
        }


        doc.save(`${student?.billNo}_bill.pdf`);
    };

    const exportPDF = () => {
        const doc = new jsPDF();

        doc.text(`Bill Details`, 14, 10);
        autoTable(doc, {
            startY: 20,
            head: [['S.No', 'Bill.No', 'Register No', 'Academic Year', 'Term', 'Fees Title', 'Payment Mode', 'Particulars', 'Amount', 'Date']],
            body: studentFeesDetails.map((details, index) => [
                index + 1,
                details.billNo ?? '-',
                registerNo,
                details.academicYear ?? '-',
                `${details.academicPeriod ?? '-'} ${details.feesType ?? '-'}`,
                details.title ?? '-',
                details.paymentMode ?? '-',
                details.particulars ?? '-',
                details.amount ?? 0,
                LocalFormatDate(details.date ?? '-'),
            ]),
            theme: 'plain',
            styles: {
                lineWidth: 0.1,
                lineColor: 0,
                textColor: 0,
                fontSize: 8
            },
            headStyles: {
                lineWidth: 0.1,
                lineColor: 0,
                textColor: 0,
                fontStyle: 'bold'
            },
            columnStyles: {
                0: { halign: 'left' },
                1: { halign: 'left' },
                2: { halign: 'left' },
                3: { halign: 'left' },
                4: { halign: 'left' },
                5: { halign: 'left' },
                6: { halign: 'left' },
                7: { halign: 'left' },
                8: { halign: 'right' },
                9: { halign: 'left' }
            },
        });


        doc.save(`${registerNo}_fees_details.pdf`);
    };

    const exportCSV = () => {
        let csvContent = 'S.No, Bill.No, Register No, Academic Year, Term, Fees Title, Payment Mode, Particulars, Amount, Date\n';

        studentFeesDetails.forEach((details, index) => {
            const row = [
                index + 1,
                details.billNo,
                registerNo,
                details.academicYear,
                `${details.academicPeriod} ${details.feesType}`,
                details.title,
                details.paymentMode ?? '-',
                details.particulars ?? '-',
                details.amount,
                LocalFormatDate(details.date),
            ].join(',');
            csvContent += row + '\n';
        });

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${registerNo}_fees_details.pdf`;
        link.click();
    };

    const clearHandler = () => {
        setRegisterNo('');
        setFeesAmounts({});
        setStudentFeesList([]);
        setStudentMiscFeesList([]);
        setStudentFeesDetails([]);
        setTitle('');
        setAcademicPeriod('');
        setIsModalOpen(false);
        setId(0);
        setBillNo(0);
        setFeesType('year');
        setAcademicYear('');
        setAmount('');
        setDate('');
        setParticulars('');
        setAmountError('');
        setPaymentMode('cash');
        setShowDeleteConfirmation(false);
        setShowDownloadButton(false)
    };

    useEffect(() => {
        const newFeesAmounts: FeesAmounts = {};

        studentFeesList.forEach((student, index) => {
            if (student.amount === student.scAmount && student.amountPaid === 0) {
                newFeesAmounts[`${student.studentId}-${index}`] = student.amount;
            }
        });

        setFeesAmounts((prev) => ({ ...prev, ...newFeesAmounts }));
    }, [studentFeesList]);

    const cancelBillHandler = () => {
        if (!selectedBill) return;

        const cancelledBillData = billDetails.map(item => ({
            studentId: item.studentId,
            academicYear: item.academicYear,
            feesType: item.feesType,
            academicPeriod: item.academicPeriod,
            title: item.title,
            paymentMode: item.paymentMode,
            particulars: `Cancellation of Bill No: ${item.billNo}`,
            amount: -Math.abs(item.amount),
            created_by: authUser.userId,
            status: 'cancelled',
        }));

        const filteredCancelledData = cancelledBillData.filter(data => data.amount < 0);

        if (filteredCancelledData.length === 0) {
            toast.error("No bill data to cancel.", { position: 'top-right' });
            return;
        }

        const data = { filteredFeesData: filteredCancelledData };

        setLoading(true);
        axios.post(`${apiUrl}/studentFee/stuPaymentByRegNo/`, data)
            .then((response) => {
                console.log(response.data);
                if (response.data.error) {
                    toast.error(response.data.message, { position: 'top-right' });
                } else {
                    if (response.status === 200) {
                        toast.success(response.data.message, { position: 'top-right' });
                        const billNo = response.data.data[0].billNo;
                        const id = response.data.data[0].studentId;

                        axios.get<{ data: StudentFeesPayment[] }>(`${apiUrl}/studentFee/paymentPDF/?id=${id}&billNo=${billNo}`)
                            .then((response) => {
                                if (response.status === 200 && response.data.data) {
                                    const studentFeesList: StudentFeesPayment[] = response.data.data;
                                    setShowDownloadButton(true);

                                    if (studentFeesList.length > 0) {
                                        generatePDF(studentFeesList, "Original Cancellation bill");
                                    }
                                }
                            });

                        fetchFeesDetails(registerNo);
                        fetchStudentFeesList(registerNo ? registerNo : '0');
                        setFeesAmounts({});
                    }
                }
            })
            .catch((err) => {
                toast.error(err.response.data.message, { position: 'top-right' });
                console.error("Error:", err.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const deleteHandler = (id: number, title: string) => {
        setId(id);
        setTitle(title)
        openDeleteConfirmation();
    };

    const confirmDeleteHandler = () => {
        axios.delete(`${apiUrl}/studentFee/?id=${ids}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                closeDeleteConfirmation();
                fetchFeesDetails(registerNo)
                fetchStudentFeesList(registerNo ? registerNo : '0')

            })
            .catch((error) => {
                toast.error(error.response.data.message, { position: 'top-right' });
            });
    };

    const totalBillAmount = billDetails.reduce((sum, field) => sum + Number(field.amount), 0);
    const formattedTotal = rupeeFormat(totalBillAmount);

    return (
        <Main>
            <PageContainer title="Student Cancel Billing">
                <div className="d-flex justify-content-center align-items-center my-4 mt-0">
                    <div style={{ width: "300px" }}>
                        <Select
                            // className="form-control"
                            value={selectOptions.find(option => option.value === registerNo) || null}
                            onChange={(selectedOption) => selectedOption && setRegisterNo(selectedOption.value)}
                            options={selectOptions}
                            placeholder="Select Register Number"
                            isSearchable
                        />
                    </div>
                    <Button variant="primary" style={{ marginLeft: "5px", backgroundColor: '#10296C' }} onClick={() => { fetchStudentFeesList(registerNo); fetchFeesDetails(registerNo) }}>Go</Button>
                    <Button variant="secondary" style={{ marginLeft: "5px" }} onClick={clearHandler}>Clear</Button>
                </div>

                {studentFeesList.length > 0 ? (
                    <div>
                        <div className="card m-2 shadow-sm" style={{ borderLeft: "5px solid #4e73df", borderRadius: "0.35rem" }}>
                            <div className="row p-3">
                                <div className="col-sm-12 col-md-12 col-lg-4 d-flex justify-content-center align-items-center">
                                    <img
                                        src={apiUrl + "/" + studentFeesList[0]?.photoUrl}
                                        alt={studentFeesList[0]?.studentName || "Student Image"}
                                        className="student-thumbnail img-thumbnail rounded-circle shadow"
                                        style={{
                                            objectFit: "cover",
                                            height: "120px",
                                            width: "120px",
                                            border: "3px solid #f8f9fc",
                                            boxShadow: "0 0 10px rgba(0,0,0,0.1)"
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-8">
                                    <div className="p-3" style={{ backgroundColor: "#f8f9fc", borderRadius: "0.25rem" }}>
                                        <h5 className="mb-3 text-primary" style={{ borderBottom: "1px solid #e3e6f0", paddingBottom: "0.5rem" }}>
                                            <i className="fas fa-user-graduate mr-2"></i>
                                            Student Details
                                        </h5>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Name: </span>
                                                    <span style={{ color: "#8E1616" }}>{studentFeesList[0]?.studentName}</span>
                                                </h6>
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Register No: </span>
                                                    <span style={{ color: "#C62300" }}>{studentFeesList[0]?.registerNumber}</span>
                                                </h6>
                                            </div>
                                            <div className="col-md-6">
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Branch: </span>
                                                    <span className="text-gray-800">{studentFeesList[0]?.branchName}</span>
                                                </h6>
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Course: </span>
                                                    <span className="text-gray-800">{studentFeesList[0]?.courseName}</span>
                                                </h6>
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Batch: </span>
                                                    <span className="text-gray-800">{studentFeesList[0]?.batch}</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="student-fees-container">
                            <div className="fees-details-card">
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                                    <button
                                        onClick={exportPDF}
                                        style={{
                                            padding: '5px 10px',
                                            marginRight: '10px',
                                            backgroundColor: '#007bff',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Feather.Download className="mr-1" size={16} /> PDF
                                    </button>
                                    <button
                                        onClick={exportCSV}
                                        style={{
                                            padding: '5px 10px',
                                            backgroundColor: '#28a745',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Feather.Download className="mr-1" size={16} /> CSV
                                    </button>
                                </div>
                                <Card title="Bill Particulars">
                                    <table className="table text-center">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Bill.No</th>
                                                <th>Academic Year</th>
                                                <th>Term</th>
                                                <th>Fees Title</th>
                                                <th>Payment Mode</th>
                                                <th>Particulars</th>
                                                <th>Amount</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {studentFeesDetails.length > 0 ? (
                                                studentFeesDetails.map((details, index) => {
                                                    const todayDate = new Date().toISOString().split('T')[0];
                                                    return (
                                                        <tr key={index + 1}>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <a
                                                                    href="#"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleBillClick(details.billNo, details.studentId);
                                                                    }}
                                                                    style={{ color: '#007bff', textDecoration: 'none' }}
                                                                >
                                                                    {details.billNo}
                                                                </a>
                                                            </td>
                                                            <td>{details.academicYear}</td>
                                                            <td>{details.academicPeriod} {details.feesType}</td>
                                                            <td>{details.title}</td>
                                                            <td>{details.paymentMode}</td>
                                                            <td>{details.particulars !== "" ? details.particulars : "-"}</td>
                                                            <td>{details.amount}</td>
                                                            <td>{LocalFormatDate(details.date)}</td>
                                                            <td className="text-capitalize">
                                                                <Badge bg={details.status === 'cancelled' ? "danger" : "success"}>
                                                                    {details.status}
                                                                </Badge>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan={8} className="text-center text-muted">No data available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </Card>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="m-2 p-3 text-center text-muted" style={{ height: "500px" }}>
                        No student data available
                    </div>
                )}


                {/* Bill Details Modal */}
                <Modal
                    show={showBillModal}
                    onHide={() => setShowBillModal(false)}
                    size="lg"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Bill Details - {selectedBill?.billNo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {billDetails.length > 0 && (
                            <div>
                                <div className="row mb-3">
                                    <div className="col-md-7">
                                        <p><strong>Name:</strong> {billDetails[0].studentName}</p>
                                        <p><strong>Register No:</strong> {billDetails[0].registerNumber}</p>
                                        <p><strong>Course:</strong> {billDetails[0].courseName}</p>
                                    </div>
                                    <div className="col-md-5">
                                        <p><strong>Bill No:</strong> {billDetails[0].billNo}</p>
                                        <p><strong>Date & Time:</strong> {LocalFormatDate(billDetails[0].date)} {billDetails[0].time}</p>
                                        <p><strong>Mode:</strong> {billDetails[0].paymentMode ? billDetails[0].paymentMode.charAt(0).toUpperCase() + billDetails[0].paymentMode.slice(1) : ''}</p>
                                    </div>
                                </div>

                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className='text-center'>Particulars</th>
                                            <th className='text-center'>Status</th>
                                            <th className='text-right'>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {billDetails.map((field, index) => (
                                            <tr key={index}>
                                                <td>{field.title}</td>
                                                <td className='text-center'>
                                                    {field.status === 'cancelled' ? (
                                                        <span className="badge badge-danger">Cancelled</span>
                                                    ) : (
                                                        <span className="badge badge-success">Active</span>
                                                    )}
                                                </td>
                                                <td className={`text-right ${field.amount < 0 ? 'text-danger' : ''}`}>
                                                    {rupeeFormat(field.amount)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan={2} className='text-right'>Total</th>
                                            <th className={`text-right ${totalBillAmount < 0 ? 'text-danger' : ''}`}>
                                                {rupeeFormat(totalBillAmount)}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {billDetails.length > 0 && billDetails[0].status === 'cancelled' ? (
                            <Button
                                variant="primary"
                                onClick={() => generatePDF(billDetails, "Duplicate Cancellation Bill")}
                            >
                                <Feather.Download className="mr-1" size={16} /> Download Receipt
                            </Button>
                        ) : (
                            <Button
                                variant="danger"
                                onClick={cancelBillHandler}
                                disabled={loading}
                            >
                                {loading ? 'Cancelling...' : 'Cancel Bill'}
                            </Button>
                        )}
                        <Button variant="secondary" onClick={() => setShowBillModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Deletion Confirmation Modal */}
                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this fee record?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>Cancel</Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            </PageContainer>
            <ToastContainer />
        </Main>
    );
};

export default StudentFeesCancelByRegNo;