import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Feather from 'react-feather';
import { useDispatch } from 'react-redux';
import Main from '../components/custom/Main';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { fetchUser } from '../redux/actions/userActions';
import { AppDispatch } from '../redux/store';
import Card from '../components/custom/Card';
import { LocalFormatDate } from '../config/functions';

const TotalFeesSummaryReport = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [summaryData, setSummaryData] = useState<any>({});

    useEffect(() => {
        dispatch(fetchUser());
        fetchTotalFeesSummary();
    }, [dispatch]);

    useEffect(() => {
        fetchTotalFeesSummary();
    }, [])

    const fetchTotalFeesSummary = () => {
        axios.get<{ data: any }>(`${apiUrl}/student/totalFeesSummary/`)
            .then(response => {
                setSummaryData(response.data.data[0]); // Assuming the API returns an array with one object
            })
            .catch(error => console.error("Error fetching summary data:", error));
    };
    
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;

    // const downloadPDF = () => {
    //     const doc = new jsPDF({
    //         orientation: "landscape",
    //         unit: "mm",
    //         format: "a4",
    //     });

    //     const title = "OYSTER LEARNING INITIATIVE SCHOOL, THIRUVARUR";
    //     const subtitle = "Consolidated Fees Report As on - " + new Date().toLocaleDateString();
    //     const pageWidth = doc.internal.pageSize.getWidth();

    //     doc.setFontSize(16);
    //     doc.setTextColor(0, 0, 0);
    //     doc.setFont("helvetica", "bold");
    //     doc.text(title, (pageWidth - doc.getTextWidth(title)) / 2, 10);

    //     doc.setFontSize(12);
    //     doc.text(subtitle, (pageWidth - doc.getTextWidth(subtitle)) / 2, 18);

    //     const currentYear = new Date().getFullYear();
    //     const nextYear = currentYear + 1;
    //     const headers = ["", "Academic Arrear", "Misc Arrear", `Academic (${currentYear.toString().slice(-2)}-${nextYear.toString().slice(-2)})`, `Misc (${currentYear.toString().slice(-2)}-${nextYear.toString().slice(-2)})`];
    //     const rows = [
    //         [{ content: "Total Fees", styles: { fontStyle: 'bold' } }, summaryData.academic_arrear_total_fees || "-", summaryData.misc_arrear_total_fees || "-", summaryData.academic_current_total_fees || "-", summaryData.misc_current_total_fees || "-"],
    //         [{ content: "Concession", styles: { fontStyle: 'bold' } }, summaryData.academic_arrear_concession || "-", "-", summaryData.academic_current_concession || "-", "-"],
    //         [{ content: "Amount Paid", styles: { fontStyle: 'bold' } }, summaryData.academic_arrear_amount_paid || "-", summaryData.misc_arrear_amount_paid || "-", summaryData.academic_current_amount_paid || "-", summaryData.misc_current_amount_paid || "-"],
    //         [{ content: "Net Balance", styles: { fontStyle: 'bold' } }, (summaryData.academic_arrear_total_fees - summaryData.academic_arrear_amount_paid) || "-", (summaryData.misc_arrear_total_fees - summaryData.misc_arrear_amount_paid) || "-", (summaryData.academic_current_total_fees - summaryData.academic_current_amount_paid) || "-", (summaryData.misc_current_total_fees - summaryData.misc_current_amount_paid) || "-"],
    //     ];
        
    //     autoTable(doc, {
    //         head: [headers],
    //         body: rows,
    //         theme: "grid",
    //         startY: 25,
    //         tableWidth: "auto",
    //         styles: {
    //             fontSize: 10,
    //             cellPadding: 2,
    //             overflow: "linebreak",
    //             textColor: [0, 0, 0],
    //             lineColor: 0,
    //             lineWidth: 0.1,
    //         },
    //         headStyles: {
    //             fillColor: [255, 255, 255],
    //             textColor: [0, 0, 0],
    //             fontStyle: "bold",
    //             fontSize: 10,
    //             lineColor: 0,
    //             lineWidth: 0.1,
    //         },
    //         didDrawPage: (data) => {
    //             const pageNumber = doc.getNumberOfPages();
    //             const pageHeight = doc.internal.pageSize.getHeight();

    //             doc.setFontSize(8);
    //             doc.text(
    //                 `Page ${pageNumber}`,
    //                 pageWidth - 40,
    //                 pageHeight - 10
    //             );
    //         },
    //     });

    //     doc.save("consolidated_fees_report.pdf");
    // };

    const downloadPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: "a4",
        });
    
        const title = "OYSTER LEARNING INITIATIVE SCHOOL, THIRUVARUR";
        const subtitle = "Consolidated Fees Report As on - (" + LocalFormatDate(new Date().toLocaleDateString()) + ")";
        const pageWidth = doc.internal.pageSize.getWidth();
    
        doc.setFontSize(16);
        doc.setTextColor(0, 0, 0);
        doc.setFont("helvetica", "bold");
        doc.text(title, (pageWidth - doc.getTextWidth(title)) / 2, 10);
    
        doc.setFontSize(12);
        doc.text(subtitle, (pageWidth - doc.getTextWidth(subtitle)) / 2, 18);
    
        const currentYear = new Date().getFullYear();
        const nextYear = currentYear + 1;
        const headers = ["", "Academic Arrear", "Misc Arrear", `Academic (${currentYear.toString().slice(-2)}-${nextYear.toString().slice(-2)})`, `Misc (${currentYear.toString().slice(-2)}-${nextYear.toString().slice(-2)})`];
    
        const rows = [
            [{ content: "Total Fees", styles: { fontStyle: 'bold' } }, summaryData.academic_arrear_total_fees || "-", summaryData.misc_arrear_total_fees || "-", summaryData.academic_current_total_fees || "-", summaryData.misc_current_total_fees || "-"],
            [{ content: "Concession", styles: { fontStyle: 'bold' } }, summaryData.academic_arrear_concession || "-", "-", summaryData.academic_current_concession || "-", "-"],
            [{ content: "Amount Paid", styles: { fontStyle: 'bold' } }, summaryData.academic_arrear_amount_paid || "-", summaryData.misc_arrear_amount_paid || "-", summaryData.academic_current_amount_paid || "-", summaryData.misc_current_amount_paid || "-"],
            [{ content: "Net Balance", styles: { fontStyle: 'bold' } }, (summaryData.academic_arrear_total_fees - summaryData.academic_arrear_amount_paid) || "-", (summaryData.misc_arrear_total_fees - summaryData.misc_arrear_amount_paid) || "-", (summaryData.academic_current_total_fees - summaryData.academic_current_amount_paid) || "-", (summaryData.misc_current_total_fees - summaryData.misc_current_amount_paid) || "-"],
        ];
    
        // Calculate overall balance
        const netBalance = [
            (summaryData.academic_arrear_total_fees || 0) - (summaryData.academic_arrear_amount_paid || 0),
            (summaryData.misc_arrear_total_fees || 0) - (summaryData.misc_arrear_amount_paid || 0),
            (summaryData.academic_current_total_fees || 0) - (summaryData.academic_current_amount_paid || 0),
            (summaryData.misc_current_total_fees || 0) - (summaryData.misc_current_amount_paid || 0),
        ].reduce((acc, val) => acc + val, 0);
    
        // Generate table
        autoTable(doc, {
            head: [headers],
            body: rows,
            theme: "grid",
            startY: 25,
            tableWidth: "auto",
            styles: {
                fontSize: 10,
                cellPadding: 2,
                overflow: "linebreak",
                textColor: [0, 0, 0],
                lineColor: 0,
                lineWidth: 0.1,
                halign: "right",
            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontStyle: "bold",
                fontSize: 10,
                lineColor: 0,
                lineWidth: 0.1,
                halign: "center",
            },
            didDrawPage: (data) => {
                const pageNumber = doc.getNumberOfPages();
                const pageHeight = doc.internal.pageSize.getHeight();
                doc.setFontSize(8);
                doc.text(`Page ${pageNumber}`, pageWidth - 40, pageHeight - 10);
            },
        });
    
        const finalY = (doc as any).lastAutoTable.finalY || 25; 
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text(
            `OVERALL BALANCE: ${netBalance.toFixed(2)}`,
            pageWidth / 2 - doc.getTextWidth(`OVERALL BALANCE: ${netBalance.toFixed(2)}`) / 2,
            finalY + 15
        );
    
        doc.save("consolidated_fees_report.pdf");
    };
    
    return (
        <Main>
            <PageContainer title="Summary Fees Details">
                <Card title="Student Fees Summary">
                    <div className="container-fluid" style={{ width: "100%" }}>
                        <table className="table table-bordered" style={{ tableLayout: "fixed", width: "100%" }}>
                            <thead style={{ backgroundColor: "#10296C", color: "#fff", textAlign: "center" }}>
                                <tr>
                                    <th></th>
                                    <th>Academic Arrear</th>
                                    <th>Misc Arrear</th>
                                    <th>Academic ({currentYear.toString().slice(-2)}-{nextYear.toString().slice(-2)})</th>
                                    <th>Misc ({currentYear.toString().slice(-2)}-{nextYear.toString().slice(-2)})</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "right" }}>
                                <tr>
                                    <th style={{ backgroundColor: "#10296C", color: "#fff" }}>Total Fees</th>
                                    <td>{summaryData.academic_arrear_total_fees || "-"}</td>
                                    <td>{summaryData.misc_arrear_total_fees || "-"}</td>
                                    <td>{summaryData.academic_current_total_fees || "-"}</td>
                                    <td>{summaryData.misc_current_total_fees || "-"}</td>
                                </tr>
                                <tr>
                                    <th style={{ backgroundColor: "#10296C", color: "#fff" }}>Concession</th>
                                    <td>{summaryData.academic_arrear_concession || "-"}</td>
                                    <td>-</td>
                                    <td>{summaryData.academic_current_concession || "-"}</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <th style={{ backgroundColor: "#10296C", color: "#fff" }}>Amount Paid</th>
                                    <td>{summaryData.academic_arrear_amount_paid || "-"}</td>
                                    <td>{summaryData.misc_arrear_amount_paid || "-"}</td>
                                    <td>{summaryData.academic_current_amount_paid || "-"}</td>
                                    <td>{summaryData.misc_current_amount_paid || "-"}</td>
                                </tr>
                                <tr>
                                    <th style={{ backgroundColor: "#10296C", color: "#fff" }}>Net Balance</th>
                                    <td>{(summaryData.academic_arrear_total_fees - summaryData.academic_arrear_amount_paid) || "-"}</td>
                                    <td>{(summaryData.misc_arrear_total_fees - summaryData.misc_arrear_amount_paid) || "-"}</td>
                                    <td>{(summaryData.academic_current_total_fees - summaryData.academic_current_amount_paid) || "-"}</td>
                                    <td>{(summaryData.misc_current_total_fees - summaryData.misc_current_amount_paid) || "-"}</td>
                                </tr>
                            </tbody>
                        </table>
                        {summaryData && (
                            <div className="col-12 m-3 d-flex justify-content-end">
                                <button
                                    className="btn btn-success mx-2"
                                    onClick={downloadPDF}
                                    style={{ fontSize: "14px" }}
                                >
                                    <Feather.Download style={{ width: "20px" }} /> PDF
                                </button>
                            </div>
                        )}
                    </div>
                </Card>
                <ToastContainer />
            </PageContainer>
        </Main>
    );
};

export default TotalFeesSummaryReport;