export const dateFormat = (date: any) => {
        const today = new Date(date);
        const yyyy = today.getFullYear();
        let mm: number | string = today.getMonth() + 1; // Months start at 0!
        let dd: number | string = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        return dd + '/' + mm + '/' + yyyy;
}

export const LocalFormatDate = (date: string) => {
        const dateObj = new Date(date)
        const day = dateObj.getDate().toString().padStart(2, '0')
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
        const year = dateObj.getFullYear()

        return [day, month, year].join('-')//20-12-2023
}

export const ViewFormatDate = (date: string) => {
        console.log(date);

        const today = new Date(date);
        const yyyy = today.getFullYear();
        let mm: number | string = today.getMonth() + 1; // Months start at 0!
        let dd: number | string = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return [yyyy, mm, dd].join('-')
}

export const onlyUnique = (array: any) => {
        return array.filter((arr: any, index: number) => array.indexOf(arr.batch) === arr.batch);
}


export const validateEmail = (email: any) => {
        return String(email)
                .toLowerCase()
                .match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
};




export const Email = new RegExp(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)

export const currencyFormat = (currency?: number | string): string => {

        return (currency !== undefined ? parseFloat(currency.toString()) : 0).toLocaleString('en-IN', {
                // style: 'currency',
                maximumFractionDigits: 2,
                currency: 'INR'
        })
}

export const rupeeFormat = (currency?: number | string): string => {

        return (currency !== undefined ? parseFloat(currency.toString()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0')
}

export const amountInWords = (amount: number): string => {
        const units = [
                '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'
        ];
        const teens = [
                'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
        ];
        const tens = [
                '', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
        ];
        const thousands = [
                '', 'Thousand', 'Lakh', 'Crore'
        ];

        if (amount === 0) return 'Zero';

        const convertToWords = (num: number): string => {
                let word = '';

                if (num > 19) {
                        word += tens[Math.floor(num / 10)] + ' ' + units[num % 10];
                } else if (num > 10) {
                        word += teens[num - 11];
                } else {
                        word += units[num];
                }

                return word.trim();
        };

        let result = '';
        let num = Math.floor(amount);
        let i = 0;

        while (num > 0) {
                const part = num % 1000;

                if (part > 0) {
                        const partInWords = convertToWords(Math.floor(part / 100)) + (Math.floor(part / 100) > 0 ? ' Hundred ' : '') +
                                convertToWords(part % 100);
                        result = partInWords + ' ' + thousands[i] + ' ' + result;
                }

                num = Math.floor(num / 1000);
                i++;
        }

        return result.trim() + ' Rupees';
};