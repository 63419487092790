import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiUrl } from '../config';
import { AuthUserType } from '../redux/actions/authUserActions';
import { BranchType, fetchBranch } from '../redux/actions/branchActions';
import { DropDownType, fetchDropDown } from '../redux/actions/dropDownAction';
import { fetchUser } from '../redux/actions/userActions';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';
import { CourseType, StudentQualificationType } from './Interface';

interface StudentInformationProps {
    id?: string;
    type: string;
}

const StudentInformation: React.FC<StudentInformationProps> = ({ id, type }) => {

    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>((state: any) => state.authUser)
    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)
    const [courseList, setCourseList] = useState<CourseType[]>([]);
    const branchList = useSelector<StoreState, BranchType[]>(state => state.branch)

    // const [id, setId] = useState<number>();
    const [branchId, setBranchId] = useState<string>('');
    const [courseId, setCourseId] = useState<string>('');
    const [registerNumber, setRegisterNumber] = useState<string>('');
    const [admissionDate, setAdmissionDate] = useState<string>('');
    const [batch, setBatch] = useState<string>('');
    const [currentSemester, setCurrentSemester] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [dob, setDob] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [religion, setReligion] = useState<string>('');
    const [community, setCommunity] = useState<string>('');
    const [caste, setCaste] = useState<string>('');
    const [fatherName, setFatherName] = useState<string>('');
    const [fatherOccupation, setFatherOccupation] = useState<string>('');
    const [motherName, setMotherName] = useState<string>('');
    const [motherOccupation, setMotherOccupation] = useState<string>('');
    const [annualIncome, setAnnualIncome] = useState<string>('');
    const [aadharNo, setAadharNo] = useState<string>('');
    const [presentAddress1, setPresentAddress1] = useState<string>('');
    const [presentAddress2, setPresentAddress2] = useState<string>('');
    const [presentAddress3, setPresentAddress3] = useState<string>('');
    const [presentAddress4, setPresentAddress4] = useState<string>('');
    const [permanentAddress1, setPermanentAddress1] = useState<string>('');
    const [permanentAddress2, setPermanentAddress2] = useState<string>('');
    const [permanentAddress3, setPermanentAddress3] = useState<string>('');
    const [permanentAddress4, setPermanentAddress4] = useState<string>('');
    const [bloodGroup, setBloodGroup] = useState<string>('');
    const [emisNo, setEmisNo] = useState<string>('');
    const [emailId, setEmailId] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [whatsappNumber, setWhatsappNumber] = useState<string>('');
    const [photoUrl, setPhotoUrl] = useState<any | null>({ name: '' });
    const [oldPhotoUrl, setOldPhotoUrl] = useState('');

    const [isSameAddress, setIsSameAddress] = useState(false);

    const [branchIdError, setBranchIdError] = useState('');
    const [courseIdError, setCourseIdError] = useState('');
    const [registerNumberError, setRegisterNumberError] = useState('');
    const [admissionDateError, setAdmissionDateError] = useState('');
    const [batchError, setBatchError] = useState('');
    const [currentSemesterError, setCurrentSemesterError] = useState('');
    const [nameError, setNameError] = useState('');
    const [dobError, setDobError] = useState('');
    const [genderError, setGenderError] = useState('');
    const [religionError, setReligionError] = useState('');
    const [communityError, setCommunityError] = useState('');
    const [casteError, setCasteError] = useState('');
    const [fatherNameError, setFatherNameError] = useState('');
    const [fatherOccupationError, setFatherOccupationError] = useState('');
    const [motherNameError, setMotherNameError] = useState('');
    const [motherOccupationError, setMotherOccupationError] = useState('');
    const [annualIncomeError, setAnnualIncomeError] = useState('');
    const [aadharNoError, setAadharNoError] = useState('');
    const [presentAddress1Error, setPresentAddress1Error] = useState('');
    const [permanentAddress1Error, setPermanentAddress1Error] = useState('');
    const [bloodGroupError, setBloodGroupError] = useState('');
    const [emisNoError, setEmisNoError] = useState('');
    const [emailIdError, setEmailIdError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [whatsappNumberError, setWhatsappNumberError] = useState('');
    const [photoUrlError, setPhotoUrlError] = useState('');
    const [createdByError, setCreatedByError] = useState('');

    const [editFlag, setEditFlag] = useState(false);


    const qualificationTitleWrapper = {
        display: "flex",
        alignItems: "center",
        textAlign: "center" as "center",
        fontSize: "1.5rem",
        fontWeight: "normal",
        color: "gray"
    };

    const lineStyleLeft = {
        flex: 0.03,
        borderBottom: "1px solid gray",
        margin: "0 10px",
        color: "gray"
    };

    const lineStyleRight = {
        flex: 1,
        borderBottom: "1px solid gray",
        margin: "0 10px",
        width: "100px",
    };


    const [qualifications, setQualifications] = useState<StudentQualificationType[]>([
        {
            id: 0,
            qualification: '',
            university: '',
            yearOfPassing: '',
            totalMark: '',
            securedMark: ''
        }
    ]);

    const addQualification = () => {
        setQualifications(prev => [
            ...prev,
            {
                id: 0,
                qualification: '',
                university: '',
                yearOfPassing: '',
                totalMark: '',
                securedMark: ''
            }
        ]);
    };

    const handleInputChange = (index: number, field: keyof StudentQualificationType, value: string | number) => {
        setQualifications(prev => {
            const updatedQualifications = [...prev];
            updatedQualifications[index] = {
                ...updatedQualifications[index],
                [field]: field === 'totalMark' || field === 'securedMark' ? Number(value) : value
            };
            return updatedQualifications;
        });
    };

    const removeQualification = (index: number) => {
        setQualifications(prev => prev.filter((_, i) => i !== index));
    };

    useEffect(() => {
        dispatch(fetchUser())
        dispatch(fetchDropDown())
        dispatch(fetchBranch())
    }, [])


    useEffect(() => {
        axios.get<{ data: CourseType[] }>(`${apiUrl}/course/`)
            .then((response) => {
                setCourseList(response.data.data);
            })
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        if (!id) return;

        axios.get(`${apiUrl}/student/?id=${id}`)
            .then((response) => {
                const data = response.data.data;
                if (data) {
                    setBranchId(data.branchId.toString());
                    setCourseId(data.courseId.toString());
                    setRegisterNumber(data.registerNumber);
                    setAdmissionDate(data.admissionDate);
                    setBatch(data.batch);
                    setCurrentSemester(data.currentSemester);
                    setName(data.name);
                    setDob(data.dob);
                    setGender(data.gender);
                    setReligion(data.religion);
                    setCommunity(data.community);
                    setCaste(data.caste);
                    setFatherName(data.fatherName);
                    setFatherOccupation(data.fatherOccupation || '');
                    setMotherName(data.motherName);
                    setMotherOccupation(data.motherOccupation || '');
                    setAnnualIncome(data.annualIncome);
                    setAadharNo(data.aadharNo);
                    setBloodGroup(data.bloodGroup);
                    setEmisNo(data.emisNo);
                    setPresentAddress1(data.presentAddress1);
                    setPresentAddress2(data.presentAddress2);
                    setPresentAddress3(data.presentAddress3 || '');
                    setPresentAddress4(data.presentAddress4 || '');
                    setPermanentAddress1(data.permanentAddress1);
                    setPermanentAddress2(data.permanentAddress2);
                    setPermanentAddress3(data.permanentAddress3 || '');
                    setPermanentAddress4(data.permanentAddress4 || '');
                    setEmailId(data.emailId);
                    setPhoneNumber(data.phoneNumber);
                    setWhatsappNumber(data.whatsappNumber);
                    setPhotoUrl({ name: data.photoUrl });
                    setOldPhotoUrl(data.photoUrl || '');

                    if (data.qualifications && data.qualifications.length > 0) {
                        setQualifications(data.qualifications.map((q: any) => ({
                            id: q.id,
                            qualification: q.qualification,
                            university: q.university,
                            yearOfPassing: q.yearOfPassing,
                            totalMark: q.totalMark.toString(),
                            securedMark: q.securedMark.toString(),
                        })));
                    } else {
                        setQualifications([{
                            id: 0,
                            qualification: '',
                            university: '',
                            yearOfPassing: '',
                            totalMark: '',
                            securedMark: ''
                        }]);
                    }
                }
            })
            .catch((error) => console.log(error));
    }, [id]);


    const addHandler = () => {

        let error = false

        if (branchId.length === 0) {
            setBranchIdError('Branch ID is required');
            error = true;
        }
        if (courseId.length === 0) {
            setCourseIdError('Course ID is required');
            error = true;
        }
        if (registerNumber.length === 0) {
            setRegisterNumberError('Register number is required');
            error = true;
        }
        if (admissionDate.length === 0) {
            setAdmissionDateError('Admission date is required');
            error = true;
        }
        if (batch.length === 0) {
            setBatchError('Batch is required');
            error = true;
        }
        if (currentSemester.length === 0) {
            setCurrentSemesterError('Current semester is required');
            error = true;
        }
        if (name.length === 0) {
            setNameError('Name is required');
            error = true;
        }
        if (dob.length === 0) {
            setDobError('Date of birth is required');
            error = true;
        }
        if (gender.length === 0) {
            setGenderError('Gender is required');
            error = true;
        }
        if (religion.length === 0) {
            setReligionError('Religion is required');
            error = true;
        }
        if (community.length === 0) {
            setCommunityError('Community is required');
            error = true;
        }
        if (caste.length === 0) {
            setCasteError('caste is required');
            error = true;
        }
        if (fatherName.length === 0) {
            setFatherNameError('Father name is required');
            error = true;
        }
        if (fatherOccupation.length === 0) {
            setFatherOccupationError('Father occupation is required');
            error = true;
        }
        if (motherName.length === 0) {
            setMotherNameError('Mother name is required');
            error = true;
        }
        if (motherOccupation.length === 0) {
            setMotherOccupationError('Mother occupation is required');
            error = true;
        }
        if (annualIncome.length === 0) {
            setAnnualIncomeError('Annual income is required');
            error = true;
        }
        if (aadharNo.length === 0) {
            setAadharNoError('Aadhar number is required');
            error = true;
        }
        if (presentAddress1.length === 0) {
            setPresentAddress1Error('Present address 1 is required');
            error = true;
        }
        if (permanentAddress1.length === 0) {
            setPermanentAddress1Error('Permanent address 1 is required');
            error = true;
        }
        if (bloodGroup.length === 0) {
            setBloodGroupError('Blood group is required');
            error = true;
        }
        if (emisNo.length === 0) {
            setEmisNoError('EMIS number is required');
            error = true;
        }
        if (phoneNumber.length === 0) {
            setPhoneNumberError('Phone number is required');
            error = true;
        }
        if (whatsappNumber.length === 0) {
            setWhatsappNumberError('WhatsApp number is required');
            error = true;
        }
        if (photoUrl.name === '') {
            setPhotoUrlError('Photo Url is required');
            error = true;
        }

        if (registerNumber.length > 50) {
            setRegisterNumberError('Register number must be within 50 characters');
            error = true;
        }
        if (admissionDate.length > 10) {
            setAdmissionDateError('Admission Date must be within 10 characters');
            error = true;
        }
        if (batch.length > 10) {
            setBatchError('Batch must be within 10 characters');
            error = true;
        }
        if (name.length > 50) {
            setNameError('Name must be within 50 characters');
            error = true;
        }
        if (dob.length > 10) {
            setDobError('Date of birth must be within 10 characters');
            error = true;
        }
        if (gender.length > 50) {
            setGenderError('Gender must be within 50 characters');
            error = true;
        }
        if (religion.length > 50) {
            setReligionError('Religion must be within 50 characters');
            error = true;
        }
        if (community.length > 15) {
            setCommunityError('Community must be within 15 characters');
            error = true;
        }
        if (caste.length > 20) {
            setCasteError('Caste must be within 20 characters');
            error = true;
        }
        if (fatherName.length > 50) {
            setFatherNameError('Father name must be within 50 characters');
            error = true;
        }
        if (fatherOccupation.length > 80) {
            setFatherOccupationError('Father occupation must be within 80 characters');
            error = true;
        }
        if (motherName.length > 50) {
            setMotherNameError('Mother name must be within 50 characters');
            error = true;
        }
        if (motherOccupation.length > 80) {
            setMotherOccupationError('Mother occupation must be within 80 characters');
            error = true;
        }
        if (aadharNo.length > 15) {
            setAadharNoError('Aadhar number must be within 15 characters');
            error = true;
        }
        if (presentAddress1.length > 80) {
            setPresentAddress1Error('Present address 1 must be within 80 characters');
            error = true;
        }
        if (permanentAddress1.length > 80) {
            setPermanentAddress1Error('Permanent address 1 must be within 80 characters');
            error = true;
        }
        if (bloodGroup.length > 10) {
            setBloodGroupError('Blood group must be within 10 characters');
            error = true;
        }
        if (emisNo.length > 30) {
            setEmisNoError('EMIS number must be within 30 characters');
            error = true;
        }
        if (phoneNumber.length > 10 || phoneNumber.length < 10) {
            setPhoneNumberError('Phone number must be 10 digits');
            error = true;
        }
        if (whatsappNumber.length > 10 || whatsappNumber.length < 10) {
            setWhatsappNumberError('WhatsApp number must be 10 digits');
            error = true;
        }
        if (photoUrl.length > 100) {
            setPhotoUrlError('Photo URL must be within 100 characters');
            error = true;
        }

        const data = new FormData();

        // Append necessary fields based on your state variables
        data.append('branchId', branchId);
        data.append('courseId', courseId);
        data.append('registerNumber', registerNumber);
        data.append('admissionDate', admissionDate);
        data.append('batch', batch);
        data.append('currentSemester', currentSemester);
        data.append('name', name);
        data.append('dob', dob);
        data.append('gender', gender);
        data.append('religion', religion);
        data.append('community', community);
        data.append('caste', caste);
        data.append('fatherName', fatherName);
        data.append('fatherOccupation', fatherOccupation);
        data.append('motherName', motherName);
        data.append('motherOccupation', motherOccupation);
        data.append('annualIncome', annualIncome);
        data.append('aadharNo', aadharNo);

        // Append address
        data.append('presentAddress1', presentAddress1);
        data.append('presentAddress2', presentAddress2);
        data.append('presentAddress3', presentAddress3);
        data.append('presentAddress4', presentAddress4);
        data.append('permanentAddress1', permanentAddress1);
        data.append('permanentAddress2', permanentAddress2);
        data.append('permanentAddress3', permanentAddress3);
        data.append('permanentAddress4', permanentAddress4);

        data.append('bloodGroup', bloodGroup);
        data.append('emisNo', emisNo);
        data.append('emailId', emailId);
        data.append('phoneNumber', phoneNumber);
        data.append('whatsappNumber', whatsappNumber);
        data.append('createdBy', authUser.userId);

        if (photoUrl && photoUrl.name) {
            data.append('photoUrl', photoUrl);
        } else if (oldPhotoUrl) {
            data.append('photoUrl', oldPhotoUrl);
        }

        data.append('qualifications', JSON.stringify(qualifications));

        if (!error) {
            axios.post(`${apiUrl}/student/`, data, { headers: { "Content-Type": "multipart/form-data" } })
                .then((response) => {
                    if (response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                        console.log(response);
                    } else {
                        toast.success(response.data.message, { position: 'top-right' });
                        clearHandler();
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    toast.error(`${error.response.data.message}`, { position: 'top-right' });
                });
        }
    };

    const updateHandler = () => {

        let error = false

        if (branchId.length === 0) {
            setBranchIdError('Branch ID is required');
            error = true;
        }
        if (courseId.length === 0) {
            setCourseIdError('Course ID is required');
            error = true;
        }
        if (registerNumber.length === 0) {
            setRegisterNumberError('Register number is required');
            error = true;
        }
        if (admissionDate.length === 0) {
            setAdmissionDateError('Admission date is required');
            error = true;
        }
        if (batch.length === 0) {
            setBatchError('Batch is required');
            error = true;
        }
        if (currentSemester.length === 0) {
            setCurrentSemesterError('Current semester is required');
            error = true;
        }
        if (name.length === 0) {
            setNameError('Name is required');
            error = true;
        }
        if (dob.length === 0) {
            setDobError('Date of birth is required');
            error = true;
        }
        if (gender.length === 0) {
            setGenderError('Gender is required');
            error = true;
        }
        if (religion.length === 0) {
            setReligionError('Religion is required');
            error = true;
        }
        if (community.length === 0) {
            setCommunityError('Community is required');
            error = true;
        }
        if (caste.length === 0) {
            setCasteError('caste is required');
            error = true;
        }
        if (fatherName.length === 0) {
            setFatherNameError('Father name is required');
            error = true;
        }
        if (fatherOccupation.length === 0) {
            setFatherOccupationError('Father occupation is required');
            error = true;
        }
        if (motherName.length === 0) {
            setMotherNameError('Mother name is required');
            error = true;
        }
        if (motherOccupation.length === 0) {
            setMotherOccupationError('Mother occupation is required');
            error = true;
        }
        if (annualIncome.length === 0) {
            setAnnualIncomeError('Annual income is required');
            error = true;
        }
        if (aadharNo.length === 0) {
            setAadharNoError('Aadhar number is required');
            error = true;
        }
        if (presentAddress1.length === 0) {
            setPresentAddress1Error('Present address 1 is required');
            error = true;
        }
        if (permanentAddress1.length === 0) {
            setPermanentAddress1Error('Permanent address 1 is required');
            error = true;
        }
        if (bloodGroup.length === 0) {
            setBloodGroupError('Blood group is required');
            error = true;
        }
        if (emisNo.length === 0) {
            setEmisNoError('EMIS number is required');
            error = true;
        }
        if (phoneNumber.length === 0) {
            setPhoneNumberError('Phone number is required');
            error = true;
        }
        if (whatsappNumber.length === 0) {
            setWhatsappNumberError('WhatsApp number is required');
            error = true;
        }
        if (photoUrl.name === '') {
            setPhotoUrlError('Photo Url is required');
            error = true;
        }

        if (registerNumber.length > 50) {
            setRegisterNumberError('Register number must be within 50 characters');
            error = true;
        }
        if (admissionDate.length > 10) {
            setAdmissionDateError('Admission Date must be within 10 characters');
            error = true;
        }
        if (batch.length > 10) {
            setBatchError('Batch must be within 10 characters');
            error = true;
        }
        if (name.length > 50) {
            setNameError('Name must be within 50 characters');
            error = true;
        }
        if (dob.length > 10) {
            setDobError('Date of birth must be within 10 characters');
            error = true;
        }
        if (gender.length > 50) {
            setGenderError('Gender must be within 50 characters');
            error = true;
        }
        if (religion.length > 50) {
            setReligionError('Religion must be within 50 characters');
            error = true;
        }
        if (community.length > 15) {
            setCommunityError('Community must be within 15 characters');
            error = true;
        }
        if (caste.length > 20) {
            setCasteError('Caste must be within 20 characters');
            error = true;
        }
        if (fatherName.length > 50) {
            setFatherNameError('Father name must be within 50 characters');
            error = true;
        }
        if (fatherOccupation.length > 80) {
            setFatherOccupationError('Father occupation must be within 80 characters');
            error = true;
        }
        if (motherName.length > 50) {
            setMotherNameError('Mother name must be within 50 characters');
            error = true;
        }
        if (motherOccupation.length > 80) {
            setMotherOccupationError('Mother occupation must be within 80 characters');
            error = true;
        }
        if (aadharNo.length > 12) {
            setAadharNoError('Aadhar number must be within 15 characters');
            error = true;
        }
        if (presentAddress1.length > 80) {
            setPresentAddress1Error('Present address 1 must be within 80 characters');
            error = true;
        }
        if (permanentAddress1.length > 80) {
            setPermanentAddress1Error('Permanent address 1 must be within 80 characters');
            error = true;
        }
        if (bloodGroup.length > 10) {
            setBloodGroupError('Blood group must be within 10 characters');
            error = true;
        }
        if (emisNo.length > 30) {
            setEmisNoError('EMIS number must be within 30 characters');
            error = true;
        }
        if (phoneNumber.length > 10 || phoneNumber.length < 10) {
            setPhoneNumberError('Phone number must be 10 digits');
            error = true;
        }
        if (whatsappNumber.length > 10 || whatsappNumber.length < 10) {
            setWhatsappNumberError('WhatsApp number must be 10 digits');
            error = true;
        }
        if (photoUrl.length > 100) {
            setPhotoUrlError('Photo URL must be within 100 characters');
            error = true;
        }

        const data = new FormData();

        data.append('branchId', branchId);
        data.append('courseId', courseId);
        data.append('registerNumber', registerNumber);
        data.append('admissionDate', admissionDate);
        data.append('batch', batch);
        data.append('currentSemester', currentSemester);
        data.append('name', name);
        data.append('dob', dob);
        data.append('gender', gender);
        data.append('religion', religion);
        data.append('community', community);
        data.append('caste', caste);
        data.append('fatherName', fatherName);
        data.append('fatherOccupation', fatherOccupation);
        data.append('motherName', motherName);
        data.append('motherOccupation', motherOccupation);
        data.append('annualIncome', annualIncome);
        data.append('aadharNo', aadharNo);

        // Append address
        data.append('presentAddress1', presentAddress1);
        data.append('presentAddress2', presentAddress2);
        data.append('presentAddress3', presentAddress3);
        data.append('presentAddress4', presentAddress4);
        data.append('permanentAddress1', permanentAddress1);
        data.append('permanentAddress2', permanentAddress2);
        data.append('permanentAddress3', permanentAddress3);
        data.append('permanentAddress4', permanentAddress4);

        data.append('bloodGroup', bloodGroup);
        data.append('emisNo', emisNo);
        data.append('emailId', emailId);
        data.append('phoneNumber', phoneNumber);
        data.append('whatsappNumber', whatsappNumber);

        if (photoUrl && photoUrl.name) {
            data.append('photoUrl', photoUrl);
        } else if (oldPhotoUrl) {
            data.append('photoUrl', oldPhotoUrl);
        }

        data.append('qualifications', JSON.stringify(qualifications));

        if (!error) {
            axios.post(`${apiUrl}/student/?id=${id}`, data, {
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then((response) => {
                    if (response && response.data && response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                        console.log(response);
                    } else if (response && response.data) {
                        toast.success(response.data.message, { position: 'top-right' });
                        clearHandler();
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        toast.error(`${error.response.data.message}`, { position: 'top-right' });
                    } else {
                        toast.error("An unknown error occurred.", { position: 'top-right' });
                    }
                });
        }

    };


    const clearHandler = () => {
        setBranchId('');
        setCourseId('');
        setRegisterNumber('');
        setAdmissionDate('');
        setBatch('');
        setCurrentSemester('');
        setName('');
        setDob('');
        setGender('');
        setReligion('');
        setCommunity('');
        setCaste('');
        setFatherName('');
        setFatherOccupation('');
        setMotherName('');
        setMotherOccupation('');
        setAnnualIncome('');
        setAadharNo('');

        setPresentAddress1('');
        setPresentAddress2('');
        setPresentAddress3('');
        setPresentAddress4('');
        setIsSameAddress(false)
        setPermanentAddress1('');
        setPermanentAddress2('');
        setPermanentAddress3('');
        setPermanentAddress4('');

        setBloodGroup('');
        setEmisNo('');
        setEmailId('');
        setPhoneNumber('');
        setWhatsappNumber('');

        setPhotoUrl({ name: '' });
        setOldPhotoUrl('');
        setQualifications([
            {
                id: 0,
                qualification: '',
                university: '',
                yearOfPassing: '',
                totalMark: '',
                securedMark: ''
            }
        ])

        setBranchIdError('');
        setCourseIdError('');
        setRegisterNumberError('');
        setAdmissionDateError('');
        setBatchError('');
        setCurrentSemesterError('');
        setNameError('');
        setDobError('');
        setGenderError('');
        setReligionError('');
        setCommunityError('');
        setCasteError('');
        setFatherNameError('');
        setFatherOccupationError('');
        setMotherNameError('');
        setMotherOccupationError('');
        setAnnualIncomeError('');
        setAadharNoError('');

        setBloodGroupError('');
        setEmisNoError('');
        setEmailIdError('');
        setPhoneNumberError('');
        setWhatsappNumberError('');

        setPhotoUrlError("");
    };

    let currentDate = new Date();
    let year = currentDate.getFullYear();

    let min = 0;
    let max = 10;


    let last10Years = [];
    for (let i = min; i <= max; i++) {
        let dropYear = year - i;
        last10Years.push(dropYear);
    }


    const handleCheckboxChange = () => {
        setIsSameAddress(!isSameAddress);
        if (!isSameAddress) {

            setPermanentAddress1(presentAddress1);
            setPermanentAddress2(presentAddress2);
            setPermanentAddress3(presentAddress3);
            setPermanentAddress4(presentAddress4);
        } else {
            setPermanentAddress1('');
            setPermanentAddress2('');
            setPermanentAddress3('');
            setPermanentAddress4('');
        }
    };


    return (
        <>
            {/* <Main> */}
            {/* <PageContainer title={type === 'add' ? "Add Student" : "Edit Student"}>
                <Card title="Student Information"> */}
            <div className="row" style={{ position: 'relative' }}>
                {/* Branch ID */}
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <label className="mt-2 mb-2">Branch <span className='text-danger'>*</span>:</label>
                    <select
                        className="form-control form-select"
                        aria-label="Default select example"
                        value={branchId}
                        onChange={(e) => setBranchId(e.target.value)}
                        disabled={type === 'edit' && !editFlag}
                    >
                        <option value={0}>Select Branch</option>
                        {branchList.map((branch) => (
                            <option key={branch.id} value={branch.id}>{branch.name}</option>
                        ))}
                    </select>

                    {branchIdError && <div className="text-danger">{branchIdError}</div>}
                </div>

                {/* Course ID */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Course <span className='text-danger'>*</span>:</label>
                    <select className="form-control form-select" aria-label="Default select example" value={courseId} onChange={(e) => setCourseId(e.target.value)} disabled={type === 'edit' && !editFlag}>
                        <option value={0}>Select Course</option>
                        {courseList?.filter(f => branchId ? f.branchId.toString() === branchId.toString() : f)?.map((course) => (
                            <option key={course.id} value={course.id}>{course.name}</option>
                        ))}
                    </select>
                    {courseIdError && <div className="text-danger">{courseIdError}</div>}
                </div>

                {/* Register Number */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Register Number <span className='text-danger'>*</span>:</label>
                    <input
                        className="form-control"
                        placeholder="Enter Register Number..."
                        type="text"
                        value={registerNumber}
                        onChange={(e) => setRegisterNumber(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    {registerNumberError && <div className="text-danger">{registerNumberError}</div>}
                </div>

                {/* Admission Date */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Admission Date <span className='text-danger'>*</span>:</label>
                    <input
                        className="form-control"
                        placeholder="Enter Admission Date..."
                        type="date"
                        value={admissionDate}
                        onChange={(e) => setAdmissionDate(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    {admissionDateError && <div className="text-danger">{admissionDateError}</div>}
                </div>

                {/* Batch */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Batch <span className='text-danger'>*</span>:</label>
                    <select name="" id="" className='form-control' value={batch} onChange={(e) => {
                        setBatch(e.target.value)
                        setBatchError('');
                    }} disabled={type === 'edit' && !editFlag}>
                        <option value="">Select Batch</option>
                        {
                            last10Years.map((ly, i) => {
                                return <option value={ly} key={i}>{ly}</option>
                            })
                        }
                    </select>
                    {batchError && <div className="text-danger">{batchError}</div>}
                </div>

                {/* Current Semester */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Current Semester <span className='text-danger'>*</span>:</label>
                    <select
                        className="form-control"
                        name="currentSemester"
                        onChange={(e) => {
                            setCurrentSemester(e.target.value);
                            setCurrentSemesterError('');
                        }}
                        value={currentSemester}
                        disabled={type === 'edit' && !editFlag}
                    >
                        <option value="">Select Semester</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                    </select>
                    {currentSemesterError && <div className="text-danger">{currentSemesterError}</div>}
                </div>

                {/* Name */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Name <span className='text-danger'>*</span>:</label>
                    <input
                        className="form-control"
                        placeholder="Enter Name..."
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    {nameError && <div className="text-danger">{nameError}</div>}
                </div>

                {/* Date of Birth */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Date of Birth <span className='text-danger'>*</span>:</label>
                    <input
                        className="form-control"
                        placeholder="Enter Date of Birth..."
                        type="date"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    {dobError && <div className="text-danger">{dobError}</div>}
                </div>
                {/* Gender */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Gender <span className='text-danger'>*</span>:</label>
                    <select
                        className="form-control"
                        style={{ width: "100%", marginRight: "20px" }}
                        name="gender"
                        onChange={(e) => {
                            setGender(e.target.value)
                            setGenderError('');
                        }}
                        value={gender}
                        disabled={type === 'edit' && !editFlag}
                    >
                        <option value="" selected>Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="trans-gender">Trans-Gender</option>
                    </select>
                    {genderError && <div className="text-danger">{genderError}</div>}
                </div>

                {/* Religion */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Religion <span className='text-danger'>*</span>:</label>
                    <select
                        className="form-control"
                        value={religion}
                        onChange={(e) => {
                            setReligion(e.target.value);
                            setReligionError('');
                        }}
                        disabled={type === 'edit' && !editFlag}
                    >
                        <option value="" disabled>Select Religion</option>
                        {
                            dropDownList.filter(dl => dl.category === 'Religion').map((dd, i) => {
                                return <option value={dd.title} key={i}>{dd.title}</option>
                            })
                        }

                    </select>
                    {religionError && <div className="text-danger">{religionError}</div>}
                </div>
                {/* Community */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Community <span className='text-danger'>*</span>:</label>
                    <select
                        className="form-control"
                        value={community}
                        onChange={(e) => {
                            setCommunity(e.target.value);
                            setCommunityError('');
                        }}
                        disabled={type === 'edit' && !editFlag}
                    >
                        <option value="" disabled>Select Community</option>
                        {
                            dropDownList.filter(dl => dl.category === 'Community').map((dd, i) => {
                                return <option value={dd.title} key={i}>{dd.title}</option>
                            })
                        }


                    </select>
                    {communityError && <div className="text-danger">{communityError}</div>}
                </div>

                {/* Caste */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Caste <span className='text-danger'>*</span>:</label>
                    <select
                        className="form-control"
                        value={caste}
                        onChange={(e) => {
                            setCaste(e.target.value);
                            setCasteError('');
                        }}
                        disabled={type === 'edit' && !editFlag}
                    >
                        <option value="" disabled>Select Community</option>
                        {
                            dropDownList.filter(dl => dl.category === 'Caste').map((dd, i) => {
                                return <option value={dd.title} key={i}>{dd.title}</option>
                            })
                        }


                    </select>
                    {communityError && <div className="text-danger">{communityError}</div>}
                </div>
                {/* Father's Name */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Father's Name <span className='text-danger'>*</span>:</label>
                    <input
                        className="form-control"
                        placeholder="Enter Father's Name..."
                        type="text"
                        value={fatherName}
                        onChange={(e) => setFatherName(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    {fatherNameError && <div className="text-danger">{fatherNameError}</div>}
                </div>
                {/* Father's Occupation */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Father's Occupation <span className='text-danger'>*</span>:</label>
                    <input
                        className="form-control"
                        placeholder="Enter Father's Occupation..."
                        type="text"
                        value={fatherOccupation}
                        onChange={(e) => setFatherOccupation(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    {fatherOccupationError && <div className="text-danger">{fatherOccupationError}</div>}
                </div>

                {/* Mother's Name */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Mother's Name <span className='text-danger'>*</span>:</label>
                    <input
                        className="form-control"
                        placeholder="Enter Mother's Name..."
                        type="text"
                        value={motherName}
                        onChange={(e) => setMotherName(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    {motherNameError && <div className="text-danger">{motherNameError}</div>}
                </div>
                {/* Mother's Occupation */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Mother's Occupation <span className='text-danger'>*</span>:</label>
                    <input
                        className="form-control"
                        placeholder="Enter Mother's Occupation..."
                        type="text"
                        value={motherOccupation}
                        onChange={(e) => setMotherOccupation(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    {motherOccupationError && <div className="text-danger">{motherOccupationError}</div>}
                </div>

                {/* Annual Income */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Annual Income <span className='text-danger'>*</span>:</label>
                    <input
                        className="form-control"
                        placeholder="Enter Annual Income..."
                        type="number"
                        value={annualIncome}
                        onChange={(e) => setAnnualIncome(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    {annualIncomeError && <div className="text-danger">{annualIncomeError}</div>}
                </div>

                {/* Aadhar Number */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Aadhar Number <span className='text-danger'>*</span>:</label>
                    <input
                        className="form-control"
                        placeholder="Enter Aadhar Number..."
                        type="number"
                        value={aadharNo}
                        onChange={(e) => setAadharNo(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    {aadharNoError && <div className="text-danger">{aadharNoError}</div>}
                </div>

                {/* Blood Group */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Blood Group <span className='text-danger'>*</span>:</label>
                    <select
                        className="form-control"
                        value={bloodGroup}
                        onChange={(e) => {
                            setBloodGroup(e.target.value);
                            setBloodGroupError('');
                        }}
                        disabled={type === 'edit' && !editFlag}
                    >
                        <option value="" disabled>Select Blood Group</option>
                        {
                            dropDownList.filter(dl => dl.category === 'Blood Group').map((dd, i) => {
                                return <option value={dd.title} key={i}>{dd.title}</option>
                            })
                        }

                    </select>
                    {bloodGroupError && <div className="text-danger">{bloodGroupError}</div>}
                </div>
                {/* EMIS Number */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">EMIS / UMIS Number <span className='text-danger'>*</span>:</label>
                    <input
                        className="form-control"
                        placeholder="Enter EMIS Number..."
                        type="text"
                        value={emisNo}
                        onChange={(e) => setEmisNo(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    {emisNoError && <div className="text-danger">{emisNoError}</div>}
                </div>
                {/* Email ID */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Email ID :</label>
                    <input
                        className="form-control"
                        placeholder="Enter Email ID..."
                        type="email"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                </div>
                {/* Phone Number */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">Phone Number <span className='text-danger'>*</span>:</label>
                    <input
                        className="form-control"
                        placeholder="Enter Phone Number..."
                        type="number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    {phoneNumberError && <div className="text-danger">{phoneNumberError}</div>}
                </div>
                {/* WhatsApp Number */}
                <div className='col-sm-6 col-md-4 col-lg-3'>

                    <label className="mt-2 mb-2">WhatsApp Number <span className='text-danger'>*</span>:</label>
                    <input
                        className="form-control"
                        placeholder="Enter WhatsApp Number..."
                        type="number"
                        value={whatsappNumber}
                        onChange={(e) => setWhatsappNumber(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    {whatsappNumberError && <div className="text-danger">{whatsappNumberError}</div>}
                </div>

                <div className='col-sm-6 col-md-4 col-lg-3 custom-file'>
                    <label className="mt-2 mb-2">Photo Url <span className='text-danger'>*</span>  : </label>
                    <div className="input-group">
                        <div className="custom-file">
                            <input
                                type="file"
                                className="custom-file-input"
                                placeholder="Enter photo Url..."
                                name="photoUrl"
                                onChange={(e) => {
                                    const selectedFile = e.target.files ? e.target.files[0] : null;
                                    if (selectedFile) {
                                        setPhotoUrl(selectedFile);
                                    } else {
                                        console.log('No file selected.');
                                    }
                                }}
                                aria-describedby="inputGroupFileAddon01"
                                disabled={type === 'edit' && editFlag === false}
                            />
                            <label className="custom-file-label" htmlFor="inputGroupFile01">
                                {photoUrl.name !== "" ? photoUrl.name : 'Choose file...'}
                            </label>
                        </div>
                    </div>

                    {photoUrlError && <div className="text-danger">{photoUrlError}</div>}
                </div>

                {/* Present Address */}
                <div className='col-sm-12 col-md-4 col-lg-6'>

                    <label className="mt-2 mb-2">Present Address :</label>
                    <input
                        className="form-control"
                        placeholder="Enter Present Address Line 1..."
                        type="text"
                        value={presentAddress1}
                        onChange={(e) => setPresentAddress1(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    <input
                        className="form-control mt-2"
                        placeholder="Enter Present Address Line 2..."
                        type="text"
                        value={presentAddress2}
                        onChange={(e) => setPresentAddress2(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    <input
                        className="form-control mt-2"
                        placeholder="Enter Present Address Line 3..."
                        type="text"
                        value={presentAddress3}
                        onChange={(e) => setPresentAddress3(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    <input
                        className="form-control mt-2"
                        placeholder="Enter Present Address Line 4..."
                        type="text"
                        value={presentAddress4}
                        onChange={(e) => setPresentAddress4(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    <div className="form-check m-2">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="sameAddressCheckbox"
                            checked={isSameAddress}
                            onChange={handleCheckboxChange}
                            readOnly={type === 'edit' && editFlag === false}
                        />
                        <label className="form-check-label" htmlFor="sameAddressCheckbox">
                            Same as Present Address
                        </label>
                    </div>
                </div>
                {/* Permanent Address */}
                <div className='col-sm-12 col-md-4 col-lg-6'>

                    <label className="mt-2 mb-2">Permanent Address :</label>
                    <input
                        className="form-control"
                        placeholder="Enter Permanent Address Line 1..."
                        type="text"
                        value={permanentAddress1}
                        onChange={(e) => setPermanentAddress1(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    <input
                        className="form-control mt-2"
                        placeholder="Enter Permanent Address Line 2..."
                        type="text"
                        value={permanentAddress2}
                        onChange={(e) => setPermanentAddress2(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    <input
                        className="form-control mt-2"
                        placeholder="Enter Permanent Address Line 3..."
                        type="text"
                        value={permanentAddress3}
                        onChange={(e) => setPermanentAddress3(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                    <input
                        className="form-control mt-2"
                        placeholder="Enter Permanent Address Line 4..."
                        type="text"
                        value={permanentAddress4}
                        onChange={(e) => setPermanentAddress4(e.target.value)}
                        readOnly={type === 'edit' && editFlag === false}
                    />
                </div>

                <div className="col-12 mt-4">
                    <div style={qualificationTitleWrapper}>
                        <div style={lineStyleLeft} ></div>
                        <h5>Student Qualifications</h5>
                        <div style={lineStyleRight}></div>
                    </div>
                    <div className="col mt-3 mb-3 me-4 d-flex justify-content-end">
                        <Button variant="success" onClick={addQualification} className="mt-3 ">
                            <FaPlus /> Add Another Qualification
                        </Button>
                    </div>
                    {qualifications.map((qualification, index) => (
                        <div key={qualification.id} className="qualification-set mb-4">
                            <div className="row">
                                <div className='col'>

                                    <label className="mt-2 mb-2">Qualification :</label>
                                    <select
                                        className="form-control"
                                        value={qualification.qualification}
                                        onChange={(e) => handleInputChange(index, 'qualification', e.target.value)}
                                        disabled={type === 'edit' && !editFlag}
                                    >
                                        <option value="" disabled>Select Qualification</option>
                                        {
                                            dropDownList.filter(dl => dl.category === 'Qualification').map((dd, i) => {
                                                return <option value={dd.title} key={i}>{dd.title}</option>
                                            })
                                        }

                                    </select>
                                </div>
                                <div className="col">
                                    <label className="mt-2 mb-2"> Board/University :</label>
                                    <input
                                        className="form-control"
                                        placeholder="Enter University..."
                                        type="text"
                                        value={qualification.university}
                                        onChange={(e) => handleInputChange(index, 'university', e.target.value)}
                                        readOnly={type === 'edit' && editFlag === false}
                                    />
                                </div>
                                <div className="col">
                                    <label className="mt-2 mb-2">Year of Passing :</label>
                                    <input
                                        className="form-control"
                                        placeholder="Enter Year of Passing..."
                                        type="number"
                                        value={qualification.yearOfPassing}
                                        onChange={(e) => handleInputChange(index, 'yearOfPassing', e.target.value)}
                                        readOnly={type === 'edit' && editFlag === false}
                                    />
                                </div>
                                <div className="col">
                                    <label className="mt-2 mb-2">Total Marks :</label>
                                    <input
                                        className="form-control"
                                        placeholder="Enter Total Marks..."
                                        type="number"
                                        value={qualification.totalMark}
                                        onChange={(e) => handleInputChange(index, 'totalMark', e.target.value)}
                                        readOnly={type === 'edit' && editFlag === false}
                                    />
                                </div>
                                <div className="col">
                                    <label className="mt-2 mb-2">Secured Marks :</label>
                                    <input
                                        className="form-control"
                                        placeholder="Enter Secured Marks..."
                                        type="number"
                                        value={qualification.securedMark}
                                        onChange={(e) => handleInputChange(index, 'securedMark', e.target.value)}
                                        readOnly={type === 'edit' && editFlag === false}
                                    />
                                </div>

                                <div className="col-1">
                                    {index > 0 && (
                                        <Button variant="danger" onClick={() => removeQualification(index)} className="" style={{ marginTop: "45%" }}>
                                            <FaMinus />
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="col mt-5 m-4 d-flex justify-content-end">
                    {
                        type === 'edit' ? (
                            <>
                                {editFlag ? (
                                    <>
                                        <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                                            Clear
                                        </button>
                                        <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button>
                                    </>
                                ) : (
                                    <button className="btn btn-sm btn-success" onClick={() => setEditFlag(true)}>Edit</button>
                                )}
                            </>
                        ) : (
                            <>
                                <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                                    Clear
                                </button>
                                <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>
                            </>
                        )
                    }

                </div>
            </div>
            <ToastContainer position="top-right" />
            {/* </Card>
            </PageContainer> */}
            {/* </Main> */}
        </>
    );
}

export default StudentInformation;





