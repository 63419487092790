import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Papa from 'papaparse';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Feather from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import CollapseCard from '../components/custom/CollapseCard';
import Main from '../components/custom/Main';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { fetchUser } from '../redux/actions/userActions';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';
import { CourseType } from './Interface';
import Card from '../components/custom/Card';

const FeesSummaryReport = () => {
    const dispatch = useDispatch<AppDispatch>();
    const branchList = useSelector<StoreState, any[]>(state => state.branch);
    const authUser = useSelector<StoreState, any>(state => state.authUser);

    const [courseList, setCourseList] = useState<CourseType[]>([]);
    const [filteredStudents, setFilteredStudents] = useState<any[]>([]);
    const [branchFilter, setBranchFilter] = useState<string>('');
    const [courseFilter, setCourseFilter] = useState<string>('');
    const [batchFilter, setBatchFilter] = useState<string>('');
    const [feesTypeFilter, setFeesTypeFilter] = useState<string>('');

    useEffect(() => {
        axios.get<{ data: CourseType[] }>(`${apiUrl}/course/`)
            .then(response => setCourseList(response.data.data))
            .catch(error => console.error("Error fetching courses:", error));
    }, []);

    useEffect(() => {
        dispatch(fetchUser());
    }, [dispatch]);

    useEffect(() => {
            handleFilterChange();
    }, [branchFilter, courseFilter, batchFilter, feesTypeFilter]);

    const handleFilterChange = () => {
        axios.get<{ data: any[] }>(
            `${apiUrl}/student/studentFeesSummary/?branchId=${branchFilter}&courseId=${courseFilter}&batch=${batchFilter}&feesType=${feesTypeFilter}`
        ).then(response => {
            setFilteredStudents(response.data.data);
        }).catch(error => console.error("Error fetching students:", error));
    };

    const downloadPDF = () => {
        const doc = new jsPDF({
            orientation: "landscape",
            unit: "mm",
            format: "a4",
        });

        const title = "Student Fees Summary";
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getTextWidth(title);

        doc.setFontSize(14);
        doc.text(title, (pageWidth - titleWidth) / 2, 10);

        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        const branchName = branchList.find(branch => branch.id.toString() === branchFilter)?.name || 'All Branches';
        const courseName = courseList.find(course => course.id.toString() === courseFilter)?.name || 'All Courses';
        const batch = batchFilter || 'All Batches';
        doc.text(`Branch: ${branchName} | Course: ${courseName} | Batch: ${batch}`, 14, 18);
        doc.setFont("helvetica", "normal");

        const totalBalance = filteredStudents.reduce((sum, stu) => sum + parseFloat(stu.total_balance || "0"), 0);
        const totalAmountPaid = filteredStudents.reduce((sum, stu) => sum + parseFloat(stu.total_paid || "0"), 0);
        const totalAmount = filteredStudents.reduce((sum, stu) => sum + parseFloat(stu.total_amount || "0"), 0);

        const feesColumns = filteredStudents.length > 0
            ? Object.keys(filteredStudents[0]).filter(
                (key) =>
                    ![
                        "batch",
                        "branch_name",
                        "course_name",
                        "fees_type",
                        "academic_period",
                        "total_amount",
                        "total_paid",
                        "total_concession",
                        "total_balance",
                    ].includes(key)
            )
            : [];

        const headers = [
            "S.No",
            ...(branchFilter ? [] : ["Branch"]),
            ...(courseFilter ? [] : ["Course"]),
            ...(batchFilter ? [] : ["Batch"]),
            "Fees Type",
            "Academic Period",
            ...feesColumns,
            "Total Amount",
            "Total Concession",
            "Total Paid",
            "Total Balance",
        ];

        const rows = filteredStudents.map((stu, i) => [
            i + 1,
            ...(branchFilter ? [] : [stu.branch_name]),
            ...(courseFilter ? [] : [stu.course_name]),
            ...(batchFilter ? [] : [stu.batch]),
            stu.fees_type,
            stu.academic_period,
            ...feesColumns.map((key) => stu[key] || "-"),
            stu.total_amount,
            stu.total_concession,
            stu.total_paid,
            stu.total_balance,
        ]);

        autoTable(doc, {
            head: [headers],
            body: rows,
            theme: "grid",
            startY: 25,
            tableWidth: "auto",
            styles: {
                fontSize: 7,
                cellPadding: 2,
                overflow: "linebreak",
                textColor: [0, 0, 0],
                lineColor: 0,
                lineWidth: 0.1,
            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                fontStyle: "bold",
                fontSize: 7,
                lineColor: 0,
                lineWidth: 0.1,
            },
            didDrawPage: (data) => {
                const pageNumber = doc.getNumberOfPages();
                const pageHeight = doc.internal.pageSize.getHeight();

                doc.setFontSize(8);
                doc.text(
                    `Page ${pageNumber}`,
                    pageWidth - 40,
                    pageHeight - 10
                );
            },
        });

        if (doc.getNumberOfPages() > 1) {
            doc.setPage(doc.getNumberOfPages());
        }

        const pageHeight = doc.internal.pageSize.getHeight();

        if ((doc as any).lastAutoTable.finalY + 20 > pageHeight - 20) {
            doc.addPage();
        }

        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);
        doc.setFont("helvetica", "bold");
        doc.text(
            `Total Amount        : ${totalAmount.toFixed(2)}`,
            pageWidth - 70,
            (doc as any).lastAutoTable.finalY + 10
        );
        doc.text(
            `Total Amount Paid: ${totalAmountPaid.toFixed(2)}`,
            pageWidth - 70,
            (doc as any).lastAutoTable.finalY + 15
        );
        doc.text(
            `Total Balance        : ${totalBalance.toFixed(2)}`,
            pageWidth - 70,
            (doc as any).lastAutoTable.finalY + 20
        );
        doc.setFont("helvetica", "normal");

        doc.save("student_fees_summary.pdf");
    };

    const downloadCSV = () => {
        const feesColumns = filteredStudents.length > 0
            ? Object.keys(filteredStudents[0])
                .filter(key => ![
                    "batch",
                    "branch_name",
                    "course_name",
                    "fees_type",
                    "academic_period",
                    "total_amount",
                    "total_paid",
                    "total_concession",
                    "total_balance",
                ].includes(key))
            : [];

        const csvData = filteredStudents.map((stu, i) => ({
            'S.No': i + 1,
            'Branch': stu.branch_name,
            'Course': stu.course_name,
            'Batch': stu.batch,
            'Fees Type': stu.fees_type,
            'Academic Period': stu.academic_period,
            ...feesColumns.reduce((acc: { [key: string]: any }, key) => {
                acc[key] = stu[key];
                return acc;
            }, {}),
            'Total Amount': stu.total_amount,
            'Total Concession': stu.total_concession,
            'Total Paid': stu.total_paid,
            'Total Balance': stu.total_balance
        }));

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'student_fees_summary.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const last10Years = Array.from({ length: 11 }, (_, i) => new Date().getFullYear() - i);

    return (
        <Main>
            <PageContainer title="Summary Fees Details">
                <Card title="Student Fees Summary">
                    <div className="row mb-4">
                        {/* Branch Filter */}
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Branch:</label>
                            <select className="form-control form-select" value={branchFilter} onChange={(e) => setBranchFilter(e.target.value)}>
                                <option value="">Select Branch</option>
                                {branchList.map(branch => (
                                    <option key={branch.id} value={branch.id}>{branch.name}</option>
                                ))}
                            </select>
                        </div>

                        {/* Course Filter */}
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Course:</label>
                            <select className="form-control form-select" value={courseFilter} onChange={(e) => setCourseFilter(e.target.value)}>
                                <option value="">Select Course</option>
                                {courseList
                                    .filter(f => !branchFilter || f.branchId.toString() === branchFilter.toString())
                                    .map(course => (
                                        <option key={course.id} value={course.id}>{course.name}</option>
                                    ))}
                            </select>
                        </div>

                        {/* Batch Filter */}
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Batch:</label>
                            <select className='form-control' value={batchFilter} onChange={(e) => setBatchFilter(e.target.value)}>
                                <option value="">Select Batch</option>
                                {last10Years.map(year => (
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>

                        {/* Fees Type Filter */}
                        <div className='col-sm-6 col-md-4 col-lg-3'>
                            <label className="mt-3 mb-3">Fees Type:</label>
                            <select className='form-control' value={feesTypeFilter} onChange={(e) => setFeesTypeFilter(e.target.value)}>
                                <option value="">Select Fees Type</option>
                                <option value="year">Year</option>
                                <option value="semester">Semester</option>
                            </select>
                        </div>
                    </div>

                    {/* Table */}
                    <div className="container-fluid" style={{ width: "100%" }}>
                        {filteredStudents.length > 0 && (
                            <div className="col-12 m-3 d-flex justify-content-end">
                                <button
                                    className="btn btn-success mx-2"
                                    onClick={downloadPDF}
                                    style={{ fontSize: "14px" }}
                                >
                                    <Feather.Download style={{ width: "20px" }} /> PDF
                                </button>
                                <button
                                    className="btn btn-success mx-2"
                                    onClick={downloadCSV}
                                    style={{ fontSize: "14px" }}
                                >
                                    <Feather.Download style={{ width: "20px" }} /> CSV
                                </button>
                            </div>
                        )}
                        <div className="container-fluid" style={{ width: "100%", overflowX: "auto" }}>
                            <table className="table table-striped" style={{ tableLayout: "fixed", width: "100%" }}>
                                <thead style={{ backgroundColor: "#10296C", color: "#fff" }}>
                                    <tr style={{ fontSize: "15px" }}>
                                        <th style={{ width: "75px" }}>S.No</th>
                                        <th style={{ width: "150px" }}>Branch</th>
                                        <th style={{ width: "150px" }}>Course</th>
                                        <th style={{ width: "100px" }}>Batch</th>
                                        <th style={{ width: "150px" }}>Fees Type</th>
                                        <th style={{ width: "150px" }}>Academic Period</th>
                                        {/* Dynamically render fees columns */}
                                        {filteredStudents.length > 0 &&
                                            Object.keys(filteredStudents[0])
                                                .filter(key => ![
                                                    "batch",
                                                    "branch_name",
                                                    "course_name",
                                                    "fees_type",
                                                    "academic_period",
                                                    "total_amount",
                                                    "total_paid",
                                                    "total_concession",
                                                    "total_balance",
                                                ].includes(key))
                                                .map((key, index) => (
                                                    <th key={index} style={{ width: "150px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={key}>
                                                        {key}
                                                    </th>
                                                ))}
                                        <th style={{ width: "150px" }}>Total Amount</th>
                                        <th style={{ width: "150px" }}>Total Paid</th>
                                        <th style={{ width: "150px" }}>Total Concession</th>
                                        <th style={{ width: "100px" }}>Total Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredStudents.map((stu, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={stu.branch_name}>
                                                {stu.branch_name}
                                            </td>
                                            <td style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={stu.course_name}>
                                                {stu.course_name}
                                            </td>
                                            <td>{stu.batch}</td>
                                            <td>{stu.fees_type}</td>
                                            <td>{stu.academic_period}</td>
                                            {/* Dynamically render fees values */}
                                            {Object.keys(stu)
                                                .filter(key => ![
                                                    "batch",
                                                    "branch_name",
                                                    "course_name",
                                                    "fees_type",
                                                    "academic_period",
                                                    "total_amount",
                                                    "total_paid",
                                                    "total_concession",
                                                    "total_balance",
                                                ].includes(key))
                                                .map((key, index) => (
                                                    <td key={index} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={stu[key]}>
                                                        {stu[key]}
                                                    </td>
                                                ))}
                                            <td>{stu.total_amount}</td>
                                            <td>{stu.total_paid}</td>
                                            <td>{stu.total_concession}</td>
                                            <td>{stu.total_balance}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Card>
                <ToastContainer />
            </PageContainer>
        </Main>
    );
};

export default FeesSummaryReport;