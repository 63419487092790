import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Badge } from "react-bootstrap";
import Main from "../components/custom/Main";
import PageContainer from "../components/custom/PageContainer";
import { apiUrl } from "../config";
import "../styles/Dashboard.css";
import CollapseCard from "../components/custom/CollapseCard";

type StudentCount = {
  branchName: string;
  courseName: string;
  batch: string;
  studentCount: number;
};

const Dashboard: React.FC = () => {
  const [students, setStudents] = useState<StudentCount[]>([]);
  const [filteredStudents, setFilteredStudents] = useState<StudentCount[]>([]);
  const [filters, setFilters] = useState({
    batch: "",
    branch: "",
    course: ""
  });
  const [uniqueBatches, setUniqueBatches] = useState<string[]>([]);
  const [uniqueBranches, setUniqueBranches] = useState<string[]>([]);
  const [uniqueCourses, setUniqueCourses] = useState<string[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [students, filters]);

  const fetchData = () => {
    axios
      .get<{ data: StudentCount[] }>(`${apiUrl}/student/studentCountByCourse/`)
      .then((response) => {
        setStudents(response.data.data);
        const batches = Array.from(new Set(response.data.data.map(item => item.batch)));
        const branches = Array.from(new Set(response.data.data.map(item => item.branchName)));
        const courses = Array.from(new Set(response.data.data.map(item => item.courseName)));

        setUniqueBatches(batches);
        setUniqueBranches(branches);
        setUniqueCourses(courses);
      })
      .catch((error) => console.log(error));
  };

  const applyFilters = () => {
    let result = [...students];

    if (filters.batch) {
      result = result.filter(student => student.batch === filters.batch);
    }

    if (filters.branch) {
      result = result.filter(student => student.branchName === filters.branch);
    }

    if (filters.course) {
      result = result.filter(student => student.courseName === filters.course);
    }

    setFilteredStudents(result);
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const resetFilters = () => {
    setFilters({
      batch: "",
      branch: "",
      course: ""
    });
  };

  const totalStudents = filteredStudents.reduce((sum, student) => sum + student.studentCount, 0);

  return (
    <Main>
      <PageContainer title="Dashboard">
        <CollapseCard
          title="Student Counts by Course"
        >
          <div className="filter-section mb-4 p-3 bg-light rounded">
            <Row className="g-3">
              <Col className="d-flex justify-content-center">
                <Form.Group controlId="batchFilter">
                  <Form.Label className="fw-bold">Batch</Form.Label>
                  <br />
                  <Form.Select
                    name="batch"
                    value={filters.batch}
                    onChange={handleFilterChange}
                    className="form-select-sm"
                  >
                    <option value="">All Batches</option>
                    {uniqueBatches.map((batch, index) => (
                      <option key={index} value={batch}>{batch}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="branchFilter">
                  <Form.Label className="fw-bold">Branch</Form.Label>
                  <Form.Select
                    name="branch"
                    value={filters.branch}
                    onChange={handleFilterChange}
                    className="form-select-sm"
                  >
                    <option value="">All Branches</option>
                    {uniqueBranches.map((branch, index) => (
                      <option key={index} value={branch}>{branch}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col className="d-flex justify-content-center">
                <Form.Group controlId="courseFilter">
                  <Form.Label className="fw-bold">Course</Form.Label>
                  <br />
                  <Form.Select
                    name="course"
                    value={filters.course}
                    onChange={handleFilterChange}
                    className="form-select-sm"
                  >
                    <option value="">All Courses</option>
                    {uniqueCourses.map((course, index) => (
                      <option key={index} value={course}>{course}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col className="d-flex align-items-end justify-content-start">
                <button
                  className="btn btn-sm btn-outline-primary w-50"
                  onClick={resetFilters}
                >
                  Reset Filters
                </button>
              </Col>
              <Col className="d-flex align-items-end justify-content-end" style={{ marginRight: "20px"}}>
                <Badge bg="primary" className="fs-6 p-2" style={{ fontSize: "1rem" }}>
                  Total Students: {totalStudents}
                </Badge>
              </Col>
            </Row>
          </div>

          <div className="table-responsive">
            <table className="table table-hover align-middle">
              <thead className="table-primary">
                <tr>
                  <th>S.No</th>
                  <th>Batch</th>
                  <th>Branch</th>
                  <th>Course</th>
                  <th className="text-center">Students</th>
                </tr>
              </thead>
              <tbody>
                {filteredStudents.map((student, index) => (
                  <tr key={index} className="hover-row">
                    <td >{index + 1}</td>
                    <td>{student.batch}</td>
                    <td>{student.branchName}</td>
                    <td>{student.courseName}</td>
                    <td className={`text-center fw-bold ${student.studentCount === 0 ? 'text-danger' : 'text-success'
                      }`}>
                      {student.studentCount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CollapseCard>
      </PageContainer>
    </Main>
  );
};

export default Dashboard;