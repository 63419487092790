import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from "jspdf-autotable";
import { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Feather from 'react-feather';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../components/Spinner';
import Card from '../components/custom/Card';
import Main from '../components/custom/Main';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { LocalFormatDate, rupeeFormat } from '../config/functions';
import '../styles/StudentFees.css';
import { StudentFeesDetails, StudentFeesPayment, StudentFeesType } from './Interface';
import StudentPayment from './StudentPayment';
import { useSelector } from 'react-redux';
import { StoreState } from '../redux/reducers';
import { AuthUserType } from '../redux/actions/authUserActions';
import Select, { SingleValue } from 'react-select';

interface Discount {
    [key: string]: number;
}

interface StudentRegNo {
    id: number;
    registerNumber: string
}

const StudentConcessionByRegisterNo = () => {
    const [studentRegNo, setStudentRegNo] = useState<StudentRegNo[]>([]);
    const [studentFeesList, setStudentFeesList] = useState<StudentFeesType[]>([]);
    const [studentFeesDetails, setStudentFeesDetails] = useState<StudentFeesDetails[]>([]);
    const authUser = useSelector<StoreState, AuthUserType>((state) => state.authUser);
    const [loading, setLoading] = useState(false);
    const [registerNo, setRegisterNo] = useState<string>('');

    const [title, setTitle] = useState('');
    const [academicPeriod, setAcademicPeriod] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ids, setId] = useState<number>(0);
    const [billNo, setBillNo] = useState<number>(0);
    const [feesType, setFeesType] = useState<string>('year');
    const [academicYear, setAcademicYear] = useState<string>('');
    const [amount, setAmount] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [particulars, setParticulars] = useState<string>('');
    const [amountError, setAmountError] = useState<string>('');
    const [paymentMode, setPaymentMode] = useState<'cash' | 'bank'>('cash');
    const [disPercent, setDisPercent] = useState<Discount>({});
    const [disAmount, setDisAmount] = useState<Discount>({});
    const [remarks, setRemarks] = useState<{ [key: string]: string }>({});

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDownloadButton, setShowDownloadButton] = useState(false);

    const fetchStuRegNo = () => {
        axios.get<{ data: StudentRegNo[] }>(`${apiUrl}/student/getAllStuRegisterNo/`)
            .then((response) => {
                setStudentRegNo(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        fetchStuRegNo();
    }, []);

    const fetchStuConcessionList = (registerNo: string) => {
        axios.get<{ data: StudentFeesType[] }>(`${apiUrl}/student/studentConcession/?registerNumber=${registerNo}`)
            .then((response) => {
                setStudentFeesList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    const fetchConcessionDetails = async (registerNumber: string) => {
        if (registerNumber) {
            try {
                const response = await axios.get<{ data: StudentFeesDetails[] }>(`${apiUrl}/studentConcession/stuPaymentByRegNo/?registerNumber=${registerNumber}`);
                const data = response.data.data;
                setStudentFeesDetails(data);
                setTitle(title);
                setAcademicPeriod(academicPeriod);
                setFeesType(feesType);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const selectOptions = useMemo(() => studentRegNo.map(student => ({
        value: student.registerNumber,
        label: student.registerNumber
    })), [studentRegNo]);

    const downloadPDF = () => {
        const doc = new jsPDF();
        const title = "Student Concession Report";

        doc.setFontSize(16);
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getTextWidth(title);
        const titleX = (pageWidth - titleWidth) / 2;
        doc.text(title, titleX, 10);

        const student = studentFeesList[0];

        autoTable(doc, {
            startY: 20,
            theme: 'plain',
            styles: { fontSize: 10, lineColor: [0, 0, 0], lineWidth: 0.3 },
            head: [['Student Info', 'Details']],
            body: [
                ['Name', student.studentName],
                ['Register Number', student.registerNumber],
                ['Branch', student.branchName],
                ['Course', student.courseName],
                ['Batch', student.batch],
                ['Phone No', student.phoneNumber],
            ]
        });

        const filteredFeesData = studentFeesList.filter((data) => {
            return (data.percent ?? 0) > 0 && (data.scAmount ?? 0) > 0 && data.remarks !== "";
        });

        autoTable(doc, {
            startY: 80,
            theme: 'plain',
            styles: { fontSize: 8, lineColor: [0, 0, 0], lineWidth: 0.3 },
            headStyles: { fontSize: 8 },
            columnStyles: {
                0: { halign: 'left' },
                1: { halign: 'left' },
                2: { halign: 'left' },
                3: { halign: 'left' },
                4: { halign: 'right' },
                5: { halign: 'left' },
                6: { halign: 'right' },
                7: { halign: 'right' },
                8: { halign: 'right' },
                9: { halign: 'left' }
            },
            head: [['S.No', 'Academic Year', 'Term', 'Fees Title', 'Amount', 'Due Date', 'Dis.Pr', 'Concession', 'Balance', 'Remarks']],
            body: filteredFeesData.map((stu, i) => {
                const balance = stu.amount - (stu.scAmount ?? 0);
                return [
                    i + 1,
                    stu.academicYear,
                    stu.academicPeriod + ' ' + stu.feesType,
                    stu.title,
                    stu.amount,
                    LocalFormatDate(stu.lastDate),
                    stu.percent ?? 0,
                    stu.scAmount ?? 0,
                    balance,
                    stu.remarks ?? ""
                ];
            }),
            foot: [[
                { content: 'Total', colSpan: 4, styles: { halign: 'right' } },
                { content: rupeeFormat(totalAmount), styles: { halign: 'right' } },
                '', '', { content: rupeeFormat(totalScAmount), styles: { halign: 'right' } },
                { content: rupeeFormat(netBalance), styles: { halign: 'right' } }, ''
            ]]
        });

        doc.save(`${registerNo}_concession.pdf`);
    };

    const generatePDF = (studentFeesList: StudentFeesPayment[]) => {
        const doc = new jsPDF();
        const imgPath = studentFeesList[0]?.photo;
        const student = studentFeesList.length > 0 ? studentFeesList[0] : null;
        const title = student?.branchName || "Unknown Branch";

        doc.setFontSize(11);
        const pageWidth = doc.internal.pageSize.getWidth();

        const titleX = (pageWidth) / 2;

        doc.text(title, titleX + 5, 12, { align: 'center', maxWidth: 80 });
        doc.addImage(imgPath, 'JPEG', titleX - 70, 3, 30, 30);

        doc.setFontSize(10);
        let yPos = 20;

        const approvedText = student?.approvedBy ? `(${student?.approvedBy})` : "";
        if (approvedText) {
            const textWidth1 = doc.getTextWidth(approvedText);
            const textX1 = (pageWidth - textWidth1) / 2;
            doc.text(approvedText, textX1 + 7, yPos);
            yPos += 5;
        }

        const recognisedText = student?.recognisedBy ? `(${student?.recognisedBy})` : "";
        if (recognisedText) {
            const textWidth2 = doc.getTextWidth(recognisedText);
            const textX2 = (pageWidth - textWidth2) / 2;
            doc.text(recognisedText, textX2 + 7, yPos);
            yPos += 5;
        }

        const sponsoredText = student?.sponsoredBy ? `(${student?.sponsoredBy})` : "";
        if (sponsoredText) {
            const textWidth3 = doc.getTextWidth(sponsoredText);
            const textX3 = (pageWidth - textWidth3) / 2;
            doc.text(sponsoredText, textX3 + 7, yPos);
            yPos += 5;
        }

        const universityText = student?.affiliatedUniversity ? `(${student?.affiliatedUniversity})` : "";
        if (universityText) {
            const textWidth4 = doc.getTextWidth(universityText);
            const textX4 = (pageWidth - textWidth4) / 2;
            doc.text(universityText, textX4 + 7, yPos);
            yPos += 8;
        }

        const placeText = student?.place ? `${student?.place}` : "";
        if (placeText) {
            const textWidth5 = doc.getTextWidth(placeText);
            const textX5 = (pageWidth - textWidth5) / 2;
            doc.text(placeText, textX5 + 7, yPos);
            yPos += 8;
        }

        doc.line(9, yPos - 2, 80, yPos - 2);
        doc.line(130, yPos - 2, 200, yPos - 2);

        doc.setFontSize(14);
        const textWidth6 = doc.getTextWidth("Concession Receipt");
        const textX6 = (pageWidth - textWidth6) / 2;
        doc.text("Concession Receipt", textX6, yPos);
        yPos += 10;

        const headerRows1: any[] = [
            [
                { content: "Bill No: " + (student?.billNo), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
                { content: "Date: " + (LocalFormatDate(student?.date || "default date")), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
            ],
            [
                { content: "Name: " + (student?.studentName), colSpan: 4, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
            ],
            [
                { content: "Class: " + (student?.courseName), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
                { content: "Regn.No: " + (student?.registerNumber), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
            ],
        ];

        autoTable(doc, {
            startY: yPos,
            theme: 'plain',
            styles: { fontSize: 8, lineColor: [0, 0, 0], lineWidth: 0.3 },
            head: [],
            body: headerRows1,
        });

        const particularsData = studentFeesList
            .filter(field => field.amount > 0)
            .map((field) => [field.title, `${field.amount}`]);

        const totalAmount = studentFeesList
            .filter(field => field.amount > 0)
            .reduce((sum, field) => sum + Number(field.amount), 0);

        const footerRows3: any[] = [
            [
                { content: 'Total', styles: { halign: "right" } },
                { content: `Rs. ${totalAmount}`, styles: { halign: "left" } },
            ]
        ];

        autoTable(doc, {
            startY: (doc as any).lastAutoTable.finalY + 10,
            theme: 'plain',
            styles: { fontSize: 10 },
            head: [['Particulars', 'Rs.']],
            body: particularsData,
            foot: footerRows3,
            headStyles: {
                lineWidth: 0.3,
                lineColor: [0, 0, 0],
                cellWidth: 'auto',
                halign: 'center',
                valign: 'middle',
                fontSize: 11,
                fontStyle: 'bold',
                cellPadding: 5,
            },
            bodyStyles: {
                fontSize: 10,
                fontStyle: 'normal',
                cellPadding: 5,
                halign: 'left',
                valign: 'middle',
                lineWidth: 0.3,
                lineColor: [0, 0, 0]
            },
            footStyles: {
                lineWidth: 0.05,
                lineColor: [0, 0, 0],
                fontSize: 10,
                fontStyle: 'bold',
                cellPadding: 2,
                halign: 'right',
                valign: 'middle',
            },
            didDrawCell: (data) => {
                if (data.row.index >= 0) {
                    if (data.column.index === 0) {
                        doc.setLineWidth(0.3);
                        doc.setDrawColor(0, 0, 0);
                        doc.line(data.cell.x + data.cell.width, data.cell.y, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                    }
                    if (data.column.index === 1) {
                        doc.setLineWidth(0.3);
                        doc.setDrawColor(0, 0, 0);
                        doc.line(data.cell.x, data.cell.y, data.cell.x, data.cell.y + data.cell.height);
                    }
                }
            },
        });

        doc.line(10, (doc as any).lastAutoTable.finalY + 12, 200, (doc as any).lastAutoTable.finalY + 12);

        doc.setFontSize(10);
        // doc.setFont("times", "italic");
        // doc.text("Fees once paid will not be refunded under any circumstances.", 10, (doc as any).lastAutoTable.finalY + 25);
        doc.setFontSize(12);
        doc.setFont("times");
        doc.text("Signature of Receiving Officer", 150, (doc as any).lastAutoTable.finalY + 30);

        doc.save('student_concession_receipt.pdf');
    };

    const exportPDF = () => {
        const doc = new jsPDF();

        doc.text(`Fees Concession`, 14, 10);
        autoTable(doc, {
            startY: 20,
            head: [['S.No', 'Bill.No', 'Register No', 'Academic Year', 'Term', 'Fees Title', 'Dis.Pr', 'Dis.Amount', 'Remarks', 'Date']],
            body: studentFeesDetails.map((details, index) => [
                index + 1,
                details.concessionBillNo ?? '',
                registerNo,
                details.academicYear ?? '',
                `${details.academicPeriod ?? ''} ${details.feesType ?? ''}`,
                details.title ?? '',
                details.percent ?? 0,
                details.amount ?? 0,
                details.remarks ?? '',
                LocalFormatDate(details.date ?? ''),
            ]),
            theme: 'plain',
            styles: {
                lineWidth: 0.1,
                lineColor: 0,
                textColor: 0,
                fontSize: 8
            },
            headStyles: {
                lineWidth: 0.1,
                lineColor: 0,
                textColor: 0,
                fontStyle: 'bold'
            },
            columnStyles: {
                0: { halign: 'left' },
                1: { halign: 'left' },
                2: { halign: 'left' },
                3: { halign: 'left' },
                4: { halign: 'left' },
                5: { halign: 'left' },
                6: { halign: 'right' },
                7: { halign: 'right' },
                8: { halign: 'left' },
                9: { halign: 'left' }
            },
        });

        doc.save(`${registerNo}_concession.pdf`);
    };

    const exportCSV = () => {
        let csvContent = 'S.No, Bill.No, Register No, Academic Year, Term, Fees Title, Dis.Pr, Dis.Amount, Remarks, Date\n';

        studentFeesDetails.forEach((details, index) => {
            const row = [
                index + 1,
                details.concessionBillNo ?? '',
                registerNo,
                details.academicYear ?? '',
                `${details.academicPeriod ?? ''} ${details.feesType ?? ''}`,
                details.title ?? '',
                details.percent ?? 0,
                details.amount ?? 0,
                details.remarks ?? '',
                LocalFormatDate(details.date ?? ''),
            ].join(',');

            csvContent += row + '\n';
        });

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${registerNo}_concession.csv`;
        link.click();
    };

    const clearHandler = () => {
        setRegisterNo('');
        setDisPercent({});
        setDisAmount({});
        setRemarks({});
        setStudentFeesList([]);
        setTitle('');
        setAcademicPeriod('');
        setIsModalOpen(false);
        setId(0);
        setBillNo(0);
        setFeesType('year');
        setAcademicYear('');
        setAmount('');
        setDate('');
        setParticulars('');
        setAmountError('');
        setPaymentMode('cash');
        setShowDeleteConfirmation(false);
        setShowDownloadButton(false)
    };


    const handleDiscountPercentChange = (studentId: number, index: number, value: string) => {
        const parsedValue = parseFloat(value) || 0;
        if (parsedValue < 0 || parsedValue > 100) {
            toast.error("Discount percent must be between 0 and 100", { position: "top-right" });
            return;
        }

        const amount = studentFeesList[index].amount;
        const scAmount = studentFeesList[index].scAmount ?? 0;
        const balance = amount - scAmount;

        const discountAmount = (balance * parsedValue) / 100;

        if (discountAmount > balance) {
            toast.error("Discount amount cannot exceed balance", { position: "top-right" });
            return;
        }

        setDisPercent((prev: Discount) => ({
            ...prev,
            [`${studentId}-${index}-discountPercent`]: parsedValue,
        }));
        setDisAmount((prev: Discount) => ({
            ...prev,
            [`${studentId}-${index}-discountAmount`]: discountAmount,
        }));
    };

    const handleDiscountAmountChange = (studentId: number, index: number, value: string) => {
        const parsedValue = parseFloat(value) || 0;
        if (parsedValue < 0) {
            toast.error("Discount amount must be positive", { position: "top-right" });
            return;
        }

        const amount = studentFeesList[index].amount;
        const amountPaid = studentFeesList[index].amountPaid ?? 0;
        const balance = amount - amountPaid;

        if (parsedValue > balance) {
            toast.error("Discount amount cannot exceed balance", { position: "top-right" });
            return;
        }

        const discountPercent = (parsedValue / balance) * 100;

        setDisAmount((prev: Discount) => ({
            ...prev,
            [`${studentId}-${index}-discountAmount`]: parsedValue,
        }));
        setDisPercent((prev: Discount) => ({
            ...prev,
            [`${studentId}-${index}-discountPercent`]: discountPercent,
        }));
    };


    const handleRemarksChange = (studentId: number, index: number, value: string) => {
        setRemarks((prev: { [key: string]: string }) => ({
            ...prev,
            [`${studentId}-${index}-remarks`]: value,
        }));
    };

    const addHandler = () => {
        let error = false;

        const feesData = studentFeesList.map((student, index) => ({
            studentId: student.studentId,
            academicYear: student.academicYear,
            feesType: student.feesType,
            academicPeriod: student.academicPeriod,
            title: student.title,
            pdDiscount: disPercent[`${student.studentId}-${index}-discountPercent`] || 0,
            amount: disAmount[`${student.studentId}-${index}-discountAmount`] || 0,
            remarks: remarks[`${student.studentId}-${index}-remarks`] || "",
            created_by: authUser.userId,
        }));

        const filteredFeesData = feesData.filter((data) => {
            if (data.pdDiscount > 0 && data.amount > 0 && data.remarks === "") {
                toast.error(`Remarks cannot be empty for ${data.title}`, { position: 'top-right' });
                return false;
            }
            return data.pdDiscount > 0 && data.amount > 0 && data.remarks !== "";
        });
        if (filteredFeesData.length === 0) {
            toast.error("No fees data to submit. Please enter valid amounts.", { position: 'top-right' });
            return;
        }

        const data = { filteredFeesData };

        setLoading(true);
        if (!error) {
            axios.post(`${apiUrl}/studentConcession/stuPaymentByRegNo/`, data)
                .then((response) => {
                    if (response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                    } else {
                        if (response.status === 200) {
                            toast.success(response.data.message, { position: 'top-right' });
                            const billNo = response.data.data[0].concessionBillNo;
                            const id = response.data.data[0].studentId;

                            axios.get<{ data: StudentFeesPayment[] }>(`${apiUrl}/studentConcession/paymentPDF/?id=${id}&billNo=${billNo}`)
                                .then((response) => {
                                    if (response.status === 200 && response.data.data) {
                                        const studentFeesList: StudentFeesPayment[] = response.data.data;
                                        setShowDownloadButton(true)

                                        // console.log(studentFeesList)

                                        // if (studentFeesList.length > 0) {
                                        //     generatePDF(studentFeesList);
                                        // }
                                    }
                                });

                            fetchConcessionDetails(registerNo);
                            fetchStuConcessionList(registerNo ? registerNo : '0');
                        }
                    }
                })
                .catch((err) => {
                    toast.error("Error occurred. Please try again.", { position: 'top-right' });
                    console.error("Error:", err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };


    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const deleteHandler = (id: number, title: string) => {
        setId(id);
        setTitle(title)
        openDeleteConfirmation();
    };

    const confirmDeleteHandler = () => {
        axios.delete(`${apiUrl}/studentFee/?id=${ids}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                closeDeleteConfirmation();
                fetchConcessionDetails(registerNo)
                fetchStuConcessionList(registerNo ? registerNo : '0')

            })
            .catch((error) => {
                toast.error(error.response.data.message, { position: 'top-right' });
            });
    };

    const totalAmount = studentFeesList.reduce((acc, student) => acc + (student.amount ?? 0), 0);
    const totalScAmount = studentFeesList.reduce((acc, student) => acc + (student.scAmount ?? 0), 0);

    const netBalance = totalAmount - totalScAmount;
    const totalPay = Object.values(disAmount).reduce((acc, curr) => acc + curr, 0);

    return (
        <Main>
            <PageContainer title="Student Concession">
                <div className="d-flex justify-content-center align-items-center my-4 mt-0">
                    <div style={{ width: "300px" }}>
                        <div style={{ width: "300px" }}>
                            <Select
                                // className="form-control"
                                value={selectOptions.find(option => option.value === registerNo) || null}
                                onChange={(selectedOption) => selectedOption && setRegisterNo(selectedOption.value)}
                                options={selectOptions}
                                placeholder="Select Register Number"
                                isSearchable
                            />
                        </div>
                    </div>
                    <Button variant="primary" style={{ marginLeft: "5px", backgroundColor: '#10296C' }} onClick={() => { fetchStuConcessionList(registerNo); fetchConcessionDetails(registerNo) }}>Go</Button>
                    <Button variant="secondary" style={{ marginLeft: "5px" }} onClick={clearHandler}>Clear</Button>
                </div>

                {studentFeesList.length > 0 ? (
                    <div>
                        <div className="card m-2 shadow-sm" style={{ borderLeft: "5px solid #4e73df", borderRadius: "0.35rem" }}>
                            <div className="row p-3">
                                <div className="col-sm-12 col-md-12 col-lg-4 d-flex justify-content-center align-items-center">
                                    <img
                                        src={apiUrl + "/" + studentFeesList[0]?.photoUrl}
                                        alt={studentFeesList[0]?.studentName || "Student Image"}
                                        className="student-thumbnail img-thumbnail rounded-circle shadow"
                                        style={{
                                            objectFit: "cover",
                                            height: "120px",
                                            width: "120px",
                                            border: "3px solid #f8f9fc",
                                            boxShadow: "0 0 10px rgba(0,0,0,0.1)"
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-8">
                                    <div className="p-3" style={{ backgroundColor: "#f8f9fc", borderRadius: "0.25rem" }}>
                                        <h5 className="mb-3 text-primary" style={{ borderBottom: "1px solid #e3e6f0", paddingBottom: "0.5rem" }}>
                                            <i className="fas fa-user-graduate mr-2"></i>
                                            Student Details
                                        </h5>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Name: </span>
                                                    <span style={{ color: "#8E1616" }}>{studentFeesList[0]?.studentName}</span>
                                                </h6>
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Register No: </span>
                                                    <span style={{ color: "#C62300" }}>{studentFeesList[0]?.registerNumber}</span>
                                                </h6>
                                            </div>
                                            <div className="col-md-6">
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Branch: </span>
                                                    <span className="text-gray-800">{studentFeesList[0]?.branchName}</span>
                                                </h6>
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Course: </span>
                                                    <span className="text-gray-800">{studentFeesList[0]?.courseName}</span>
                                                </h6>
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Batch: </span>
                                                    <span className="text-gray-800">{studentFeesList[0]?.batch}</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="student-fees-container">
                            <Card title="STUDENT FEES LIST">
                                <div className="mb-3" style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    <label>Set Discount:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Enter Discount %"
                                        min="0"
                                        max="100"
                                        value={disPercent['global'] || ""}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setDisPercent((prev: Discount) => ({
                                                ...prev,
                                                global: parseFloat(value) || 0,
                                            }));
                                            studentFeesList.forEach((student, index) => {
                                                handleDiscountPercentChange(student.studentId, index, value);
                                            });
                                        }}
                                        style={{ width: "10%" }}
                                    />
                                </div>
                                {studentFeesList.length > 0 ? (
                                    <>
                                        <table className="table table-hover text-center">
                                            <thead style={{ backgroundColor: "#10296C", color: "#ffffff" }}>
                                                <tr>
                                                    <th rowSpan={2}>S.No</th>
                                                    <th rowSpan={2}>Academic Year</th>
                                                    <th rowSpan={2}>Term</th>
                                                    <th rowSpan={2}>Fees Title</th>
                                                    <th rowSpan={2}>Amount</th>
                                                    <th rowSpan={2}>Due Date</th>
                                                    <th rowSpan={2}>Concession</th>
                                                    <th rowSpan={2}>Balance</th>
                                                    <th colSpan={2}>Discount</th>
                                                    <th rowSpan={2}>Remarks</th>
                                                </tr>
                                                <tr>
                                                    <th>Percent</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {studentFeesList.map((student, index) => {
                                                    const balance = student.amount - (student.scAmount ?? 0);
                                                    return (
                                                        <tr key={`${student.studentId}-${index}`}>
                                                            <td>{index + 1}</td>
                                                            <td>{student.academicYear}</td>
                                                            <td>{student.academicPeriod} {student.feesType}</td>
                                                            <td>{student.title}</td>
                                                            <td style={{ textAlign: "right" }}>{student.amount}</td>
                                                            <td>{LocalFormatDate(student.lastDate)}</td>
                                                            <td style={{ textAlign: "right" }}>{student.scAmount ?? 0}</td>
                                                            <td style={{ textAlign: "right" }}>{balance}</td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Enter Discount %"
                                                                    min="0"
                                                                    max="100"
                                                                    value={disPercent[`${student.studentId}-${index}-discountPercent`] || ""}
                                                                    onChange={(e) => handleDiscountPercentChange(student.studentId, index, e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Enter Discount Amount"
                                                                    min="0"
                                                                    value={disAmount[`${student.studentId}-${index}-discountAmount`] || ""}
                                                                    onChange={(e) => handleDiscountAmountChange(student.studentId, index, e.target.value)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Remarks"
                                                                    value={remarks[`${student.studentId}-${index}-remarks`] || ""}
                                                                    onChange={(e) => handleRemarksChange(student.studentId, index, e.target.value)}
                                                                    required={disPercent[`${student.studentId}-${index}-discountPercent`] || disAmount[`${student.studentId}-${index}-discountAmount`] ? true : false}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                <tr style={{ fontWeight: "bold", backgroundColor: "#255F38", color: "#ffffff" }}>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>Total</td>
                                                    <td style={{ textAlign: "right" }}>{rupeeFormat(totalAmount)}</td>
                                                    <td></td>
                                                    <td style={{ textAlign: "right" }}>{rupeeFormat(totalScAmount)}</td>
                                                    <td style={{ textAlign: "right" }}>{rupeeFormat(netBalance)}</td>
                                                    <td></td>
                                                    <td style={{ textAlign: "right" }}>
                                                        <h6 style={{ fontSize: "18px" }}>
                                                            <span className="ml-2" style={{ fontWeight: "bold" }}>
                                                                {rupeeFormat(totalPay)}
                                                            </span>
                                                        </h6>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="d-flex justify-content-end">
                                            <Button
                                                className="btn btn-sm m-2"
                                                variant="primary"
                                                onClick={addHandler}
                                                style={{ width: "7%", backgroundColor: '#10296C' }}
                                            >
                                                Add
                                            </Button>
                                            {studentFeesList.length > 0 && showDownloadButton && (
                                                <button
                                                    className="btn btn-success btn-sm m-2 d-flex align-items-center"
                                                    onClick={downloadPDF}
                                                    style={{ width: "7%", fontSize: "14px" }}
                                                >
                                                    <Feather.Download className="mr-1" size={16} /> PDF
                                                </button>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <div className="text-center p-4">
                                        <h5>No student fees data available.</h5>
                                    </div>
                                )}
                            </Card>

                            <div className="fees-details-card">
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                                    <button
                                        onClick={exportPDF}
                                        style={{
                                            padding: '5px 10px',
                                            marginRight: '10px',
                                            backgroundColor: '#007bff',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Feather.Download className="mr-1" size={16} /> PDF
                                    </button>
                                    <button
                                        onClick={exportCSV}
                                        style={{
                                            padding: '5px 10px',
                                            backgroundColor: '#28a745',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Feather.Download className="mr-1" size={16} /> CSV
                                    </button>
                                </div>
                                <Card title={`${title} DETAILS`}>
                                    <table className="table text-center">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                {/* <th>Bill.No</th> */}
                                                <th>Academic Year</th>
                                                <th>Term</th>
                                                <th>Fees Title</th>
                                                <th>Dis.Percent</th>
                                                <th>Dis.Amount</th>
                                                <th>Remarks</th>
                                                <th>Date</th>
                                                {/* <th>Options</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {studentFeesDetails?.length > 0 ? (
                                                studentFeesDetails.map((details, index) => {
                                                    const todayDate = new Date().toISOString().split('T')[0];
                                                    return (
                                                        <tr key={index + 1}>
                                                            <td>{index + 1}</td>
                                                            {/* <td>{details.concessionBillNo}</td> */}
                                                            <td>{details.academicYear}</td>
                                                            <td>{details.academicPeriod} {details.feesType}</td>
                                                            <td>{details.title}</td>
                                                            <td style={{ textAlign: "right" }}>{details.percent}</td>
                                                            <td style={{ textAlign: "right" }}>{details.amount}</td>
                                                            <td>{details.remarks}</td>
                                                            <td>{LocalFormatDate(details.date)}</td>
                                                            {/* <td>
                                                                {details.date === todayDate ? (
                                                                    <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(details.id, details.title)}>
                                                                        Delete
                                                                    </button>
                                                                ) : null}
                                                            </td> */}
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan={8} className="text-center text-muted">No data available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </Card>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="m-2 p-3 text-center text-muted" style={{ height: "500px" }}>
                        No student data available
                    </div>
                )}


                {/* Deletion Confirmation Modal */}
                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this fee record?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>Cancel</Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            </PageContainer>
            <ToastContainer />
        </Main>
    );
};

export default StudentConcessionByRegisterNo;