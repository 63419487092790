import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from "jspdf-autotable";
import { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Feather from 'react-feather';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../components/Spinner';
import Card from '../components/custom/Card';
import Main from '../components/custom/Main';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { LocalFormatDate } from '../config/functions';
import '../styles/StudentFees.css';
import { StudentFeesDetails, StudentFeesPayment, StudentFeesType } from './Interface';
import StudentPayment from './StudentPayment';
import { useSelector } from 'react-redux';
import { StoreState } from '../redux/reducers';
import { AuthUserType } from '../redux/actions/authUserActions';
import Select, { SingleValue } from 'react-select';
import { DropDownType } from '../redux/actions/dropDownAction';

interface Discount {
    [key: string]: number;
}

interface StudentRegNo {
    id: number;
    registerNumber: string
}

const StudentMiscFeesByRegisterNo = () => {
    const [studentRegNo, setStudentRegNo] = useState<StudentRegNo[]>([]);
    const [studentFeesList, setStudentFeesList] = useState<StudentFeesType[]>([]);
    const [studentFeesDetails, setStudentFeesDetails] = useState<StudentFeesDetails[]>([]);
    const authUser = useSelector<StoreState, AuthUserType>((state) => state.authUser);
    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)
    const [loading, setLoading] = useState(false);
    const [registerNo, setRegisterNo] = useState<string>('');

    const [title, setTitle] = useState('');
    const [academicPeriod, setAcademicPeriod] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ids, setId] = useState<number>(0);
    const [billNo, setBillNo] = useState<number>(0);
    const [feesType, setFeesType] = useState<string>('year');
    const [academicYear, setAcademicYear] = useState<string>('');
    const [amount, setAmount] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [particulars, setParticulars] = useState<string>('');
    const [amountError, setAmountError] = useState<string>('');
    const [remarks, setRemarks] = useState<string>("");
    const [courseName, setCourseName] = useState<string | undefined>('');
    const [years, setYears] = useState<string>('');
    const [lastDate, setLastDate] = useState<string>('');
    const [courseIdError, setCourseIdError] = useState<string>('');
    const [feesTypeError, setfeesTypeError] = useState<string>('');
    const [yearsError, setYearsError] = useState<string>('');
    const [academicYearError, setAcademicYearError] = useState<string>('')
    const [noOfSemesterError, setNoOfSemesterError] = useState<string>('');
    const [noOfSemester, setNoOfSemester] = useState<string>('');
    const [gridFields, setGridFields] = useState<{ title: string, amount: string, lastDate: string }[]>([]);
    const [branchId, setBranchId] = useState<string>('');
    const [titleError, setTitleError] = useState('');
    const [remarksError, setRemarksError] = useState('');


    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDownloadButton, setShowDownloadButton] = useState(false);


    //for academic year
    let currentDate = new Date();
    let year = currentDate.getFullYear();

    let min = 0;
    let max = 10;


    let last10Years = [];
    for (let i = min; i <= max; i++) {
        let dropYear = year - i;
        last10Years.push(dropYear);
    }

    const fetchStuRegNo = () => {
        axios.get<{ data: StudentRegNo[] }>(`${apiUrl}/student/getAllStuRegisterNo/`)
            .then((response) => {
                setStudentRegNo(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        fetchStuRegNo();
    }, []);

    const fetchStuMiscFeesList = (registerNo: string) => {
        axios.get<{ data: StudentFeesType[] }>(`${apiUrl}/student/studentMiscFees/?registerNumber=${registerNo}`)
            .then((response) => {
                setStudentFeesList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    const fetchMiscFeesDetails = async (registerNumber: string) => {
        if (registerNumber) {
            try {
                const response = await axios.get<{ data: StudentFeesDetails[] }>(`${apiUrl}/studentMiscFees/stuPaymentByRegNo/?registerNumber=${registerNumber}`);
                const data = response.data.data;
                setStudentFeesDetails(data);
                setTitle(title);
                setAcademicPeriod(academicPeriod);
                setFeesType(feesType);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const selectOptions = useMemo(() => studentRegNo.map(student => ({
        value: student.registerNumber,
        label: student.registerNumber
    })), [studentRegNo]);

    const downloadPDF = () => {
        const doc = new jsPDF();
        const title = "Student Miscellaneous Fees Report";

        doc.setFontSize(16);
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getTextWidth(title);
        const titleX = (pageWidth - titleWidth) / 2;
        doc.text(title, titleX, 10);

        if (!studentFeesList.length) {
            console.error("No student data available.");
            return;
        }

        const student = studentFeesList[0];

        autoTable(doc, {
            startY: 20,
            theme: 'plain',
            styles: { fontSize: 10, lineColor: [0, 0, 0], lineWidth: 0.3 },
            head: [['Student Info', 'Details']],
            body: [
                ['Name', student.studentName],
                ['Register Number', student.registerNumber],
                ['Branch', student.branchName],
                ['Course', student.courseName],
                ['Batch', student.batch],
                ['Phone No', student.phoneNumber],
            ]
        });

        const nextTableY = (doc as any).lastAutoTable ? (doc as any).lastAutoTable.finalY + 10 : 80;

        autoTable(doc, {
            startY: nextTableY,
            theme: 'plain',
            styles: { fontSize: 9, lineColor: [0, 0, 0], lineWidth: 0.3 },
            headStyles: { fontSize: 8 },
            head: [['Academic Year', 'Term', 'Fees Title', 'Amount', 'Remarks']],
            body: [[
                academicYear,
                (feesType === 'year' ? years : noOfSemester) + " " + feesType,
                title,
                amount ?? "-",
                remarks ?? "-"
            ]]
        });

        doc.save('student_misc_fees.pdf');
    };


    const generatePDF = (studentFeesList: StudentFeesPayment[]) => {
        const doc = new jsPDF();
        const imgPath = studentFeesList[0]?.photo;
        const student = studentFeesList.length > 0 ? studentFeesList[0] : null;
        const title = student?.branchName || "Unknown Branch";

        doc.setFontSize(11);
        const pageWidth = doc.internal.pageSize.getWidth();

        const titleX = (pageWidth) / 2;

        doc.text(title, titleX + 5, 12, { align: 'center', maxWidth: 80 });
        doc.addImage(imgPath, 'JPEG', titleX - 70, 3, 30, 30);

        doc.setFontSize(10);
        let yPos = 20;

        const approvedText = student?.approvedBy ? `(${student?.approvedBy})` : "";
        if (approvedText) {
            const textWidth1 = doc.getTextWidth(approvedText);
            const textX1 = (pageWidth - textWidth1) / 2;
            doc.text(approvedText, textX1 + 7, yPos);
            yPos += 5;
        }

        const recognisedText = student?.recognisedBy ? `(${student?.recognisedBy})` : "";
        if (recognisedText) {
            const textWidth2 = doc.getTextWidth(recognisedText);
            const textX2 = (pageWidth - textWidth2) / 2;
            doc.text(recognisedText, textX2 + 7, yPos);
            yPos += 5;
        }

        const sponsoredText = student?.sponsoredBy ? `(${student?.sponsoredBy})` : "";
        if (sponsoredText) {
            const textWidth3 = doc.getTextWidth(sponsoredText);
            const textX3 = (pageWidth - textWidth3) / 2;
            doc.text(sponsoredText, textX3 + 7, yPos);
            yPos += 5;
        }

        const universityText = student?.affiliatedUniversity ? `(${student?.affiliatedUniversity})` : "";
        if (universityText) {
            const textWidth4 = doc.getTextWidth(universityText);
            const textX4 = (pageWidth - textWidth4) / 2;
            doc.text(universityText, textX4 + 7, yPos);
            yPos += 8;
        }

        const placeText = student?.place ? `${student?.place}` : "";
        if (placeText) {
            const textWidth5 = doc.getTextWidth(placeText);
            const textX5 = (pageWidth - textWidth5) / 2;
            doc.text(placeText, textX5 + 7, yPos);
            yPos += 8;
        }

        doc.line(7, yPos - 2, 70, yPos - 2);
        doc.line(150, yPos - 2, 200, yPos - 2);

        doc.setFontSize(14);
        const textWidth6 = doc.getTextWidth("MiscFees Receipt");
        const textX6 = (pageWidth - textWidth6) / 2;
        doc.text("Miscellaneous Fees Receipt", textX6, yPos);
        yPos += 10;

        const headerRows1: any[] = [
            [
                { content: "Bill No: " + (student?.billNo), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
                { content: "Date: " + (LocalFormatDate(student?.date || "default date")), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
            ],
            [
                { content: "Name: " + (student?.studentName), colSpan: 4, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
            ],
            [
                { content: "Class: " + (student?.courseName), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
                { content: "Regn.No: " + (student?.registerNumber), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
            ],
        ];

        autoTable(doc, {
            startY: yPos,
            theme: 'plain',
            styles: { fontSize: 8, lineColor: [0, 0, 0], lineWidth: 0.3 },
            head: [],
            body: headerRows1,
        });

        const particularsData = studentFeesList
            .filter(field => field.amount > 0)
            .map((field) => [field.title, `${field.amount}`]);

        const totalAmount = studentFeesList
            .filter(field => field.amount > 0)
            .reduce((sum, field) => sum + Number(field.amount), 0);

        const footerRows3: any[] = [
            [
                { content: 'Total', styles: { halign: "right" } },
                { content: `Rs. ${totalAmount}`, styles: { halign: "left" } },
            ]
        ];

        autoTable(doc, {
            startY: (doc as any).lastAutoTable.finalY + 10,
            theme: 'plain',
            styles: { fontSize: 10 },
            head: [['Particulars', 'Rs.']],
            body: particularsData,
            foot: footerRows3,
            headStyles: {
                lineWidth: 0.3,
                lineColor: [0, 0, 0],
                cellWidth: 'auto',
                halign: 'center',
                valign: 'middle',
                fontSize: 11,
                fontStyle: 'bold',
                cellPadding: 5,
            },
            bodyStyles: {
                fontSize: 10,
                fontStyle: 'normal',
                cellPadding: 5,
                halign: 'left',
                valign: 'middle',
                lineWidth: 0.3,
                lineColor: [0, 0, 0]
            },
            footStyles: {
                lineWidth: 0.05,
                lineColor: [0, 0, 0],
                fontSize: 10,
                fontStyle: 'bold',
                cellPadding: 2,
                halign: 'right',
                valign: 'middle',
            },
            didDrawCell: (data) => {
                if (data.row.index >= 0) {
                    if (data.column.index === 0) {
                        doc.setLineWidth(0.3);
                        doc.setDrawColor(0, 0, 0);
                        doc.line(data.cell.x + data.cell.width, data.cell.y, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                    }
                    if (data.column.index === 1) {
                        doc.setLineWidth(0.3);
                        doc.setDrawColor(0, 0, 0);
                        doc.line(data.cell.x, data.cell.y, data.cell.x, data.cell.y + data.cell.height);
                    }
                }
            },
        });

        doc.line(10, (doc as any).lastAutoTable.finalY + 12, 200, (doc as any).lastAutoTable.finalY + 12);

        doc.setFontSize(10);
        // doc.setFont("times", "italic");
        // doc.text("Fees once paid will not be refunded under any circumstances.", 10, (doc as any).lastAutoTable.finalY + 25);
        doc.setFontSize(12);
        doc.setFont("times");
        doc.text("Signature of Receiving Officer", 150, (doc as any).lastAutoTable.finalY + 30);

        doc.save('student_misc_fees_receipt.pdf');
    };

    const exportPDF = () => {
        const doc = new jsPDF();

        doc.text(`Miscellaneous Fees`, 14, 10);
        autoTable(doc, {
            startY: 20,
            head: [['S.No', 'Register No', 'Academic Year', 'Term', 'Fees Title', 'Amount', 'Remarks', 'Date']],
            body: studentFeesDetails.map((details, index) => [
                index + 1,
                registerNo,
                details.academicYear ?? '',
                `${details.academicPeriod ?? ''} ${details.feesType ?? ''}`,
                details.title ?? '',
                details.amount ?? 0,
                details.remarks ?? '',
                LocalFormatDate(details.date ?? ''),
            ]),
            theme: 'plain',
            styles: {
                lineWidth: 0.1,
                lineColor: 0,
                textColor: 0,
                fontSize: 8
            },
            headStyles: {
                lineWidth: 0.1,
                lineColor: 0,
                textColor: 0,
                fontStyle: 'bold'
            },
            columnStyles: {
                0: { halign: 'left' },
                1: { halign: 'left' },
                2: { halign: 'left' },
                3: { halign: 'left' },
                4: { halign: 'left' },
                5: { halign: 'right' },
                6: { halign: 'left' },
                7: { halign: 'left' },
            },
        });

        doc.save(`${registerNo}_miscfees.pdf`);
    };

    const exportCSV = () => {
        let csvContent = 'S.No, Register No, Academic Year, Term, Fees Title, Amount, Remarks, Date\n';

        studentFeesDetails.forEach((details, index) => {
            const row = [
                index + 1,
                registerNo,
                details.academicYear ?? '',
                `${details.academicPeriod ?? ''} ${details.feesType ?? ''}`,
                details.title ?? '',
                details.amount ?? 0,
                details.remarks ?? '',
                LocalFormatDate(details.date ?? ''),
            ].join(',');

            csvContent += row + '\n';
        });

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${registerNo}_miscfees.pdf`;
        link.click();
    };

    const clearHandler = () => {
        setRegisterNo('');
        setRemarks("");
        setStudentFeesList([]);
        setTitle('');
        setAcademicPeriod('');
        setIsModalOpen(false);
        setId(0);
        setBillNo(0);
        setFeesType('year');
        setAcademicYear('');
        setAmount('');
        setDate('');
        setParticulars('');
        setAmountError('');
        setShowDeleteConfirmation(false);
        setShowDownloadButton(false)
    };



    const addHandler = () => {
        let error = false;

        if (!academicYear) {
            setAcademicYearError("Academic Year is required");
            error = true;
        }
        if (!feesType) {
            toast.error("Please select a Fees Type", { position: 'top-right' });
            error = true;
        }
        if (feesType === 'year' && !years) {
            setYearsError("Year is required");
            error = true;
        }
        if (feesType === 'semester' && !noOfSemester) {
            setNoOfSemesterError("Semester is required");
            error = true;
        }
        if (!title) {
            setTitleError("Title is required");
            error = true;
        }
        if (!amount || parseFloat(amount) <= 0) {
            setAmountError("Valid Amount is required");
            error = true;
        }
        if (!remarks) {
            setRemarksError("Remarks are required");
            error = true;
        }

        if (error) return;

        const data = {
            studentId: studentFeesList[0]?.studentId,
            academicYear: academicYear,
            feesType: feesType,
            academicPeriod: feesType === 'year' ? `${years}` : `${noOfSemester}`,
            title: title,
            amount: amount,
            remarks: remarks || "",
            created_by: authUser.userId
        };

        setLoading(true);
        if (!error) {
            axios.post(`${apiUrl}/studentMiscFees/stuPaymentByRegNo/`, data)
                .then((response) => {
                    if (response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                    } else {
                        if (response.status === 200) {
                            toast.success(response.data.message, { position: 'top-right' });
                            const id = response.data.data[0].studentId;

                            axios.get<{ data: StudentFeesPayment[] }>(`${apiUrl}/studentMiscFees/paymentPDF/?id=${id}`)
                                .then((response) => {
                                    if (response.status === 200 && response.data.data) {
                                        const studentFeesList: StudentFeesPayment[] = response.data.data;
                                        setShowDownloadButton(true)
                                        setAcademicYear('');
                                        setFeesType('year');
                                        setYears('');
                                        setNoOfSemester('');
                                        setTitle('');
                                        setAmount('');
                                        setRemarks('');

                                        // if (studentFeesList.length > 0) {
                                        //     generatePDF(studentFeesList);
                                        // }
                                    }
                                });

                            fetchMiscFeesDetails(registerNo);
                            fetchStuMiscFeesList(registerNo ? registerNo : '0');
                        }
                    }
                })
                .catch((err) => {
                    toast.error("Error occurred. Please try again.", { position: 'top-right' });
                    console.error("Error:", err);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };


    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const deleteHandler = (id: number, title: string) => {
        setId(id);
        setTitle(title)
        openDeleteConfirmation();
    };

    const confirmDeleteHandler = () => {
        axios.delete(`${apiUrl}/studentFee/?id=${ids}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                closeDeleteConfirmation();
                fetchMiscFeesDetails(registerNo)
                fetchStuMiscFeesList(registerNo ? registerNo : '0')

            })
            .catch((error) => {
                toast.error(error.response.data.message, { position: 'top-right' });
            });
    };

    return (
        <Main>
            <PageContainer title="Student Miscellaneous Fees">
                <div className="d-flex justify-content-center align-items-center my-4 mt-0">
                    <div style={{ width: "300px" }}>
                        <Select
                            value={selectOptions.find(option => option.value === registerNo) || null}
                            onChange={(selectedOption) => selectedOption && setRegisterNo(selectedOption.value)}
                            options={selectOptions}
                            placeholder="Select Register Number"
                            isSearchable
                        />
                    </div>
                    <Button
                        variant="primary"
                        style={{ marginLeft: "5px", backgroundColor: '#10296C' }}
                        onClick={() => { fetchStuMiscFeesList(registerNo); fetchMiscFeesDetails(registerNo); }}
                    >
                        Go
                    </Button>
                    <Button variant="secondary" style={{ marginLeft: "5px" }} onClick={clearHandler}>
                        Clear
                    </Button>
                </div>

                {studentFeesList.length > 0 ? (
                    <div>
                        <div className="card m-2 shadow-sm" style={{ borderLeft: "5px solid #4e73df", borderRadius: "0.35rem" }}>
                            <div className="row p-3">
                                <div className="col-sm-12 col-md-12 col-lg-4 d-flex justify-content-center align-items-center">
                                    <img
                                        src={apiUrl + "/" + studentFeesList[0]?.photoUrl}
                                        alt={studentFeesList[0]?.studentName || "Student Image"}
                                        className="student-thumbnail img-thumbnail rounded-circle shadow"
                                        style={{
                                            objectFit: "cover",
                                            height: "120px",
                                            width: "120px",
                                            border: "3px solid #f8f9fc",
                                            boxShadow: "0 0 10px rgba(0,0,0,0.1)"
                                        }}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-8">
                                    <div className="p-3" style={{ backgroundColor: "#f8f9fc", borderRadius: "0.25rem" }}>
                                        <h5 className="mb-3 text-primary" style={{ borderBottom: "1px solid #e3e6f0", paddingBottom: "0.5rem" }}>
                                            <i className="fas fa-user-graduate mr-2"></i>
                                            Student Details
                                        </h5>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Name: </span>
                                                    <span style={{ color: "#8E1616" }}>{studentFeesList[0]?.studentName}</span>
                                                </h6>
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Register No: </span>
                                                    <span style={{ color: "#C62300" }}>{studentFeesList[0]?.registerNumber}</span>
                                                </h6>
                                            </div>
                                            <div className="col-md-6">
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Branch: </span>
                                                    <span className="text-gray-800">{studentFeesList[0]?.branchName}</span>
                                                </h6>
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Course: </span>
                                                    <span className="text-gray-800">{studentFeesList[0]?.courseName}</span>
                                                </h6>
                                                <h6 className="text-dark mb-2">
                                                    <span className="font-weight-bold" style={{ color: "#4e73df" }}>Batch: </span>
                                                    <span className="text-gray-800">{studentFeesList[0]?.batch}</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="student-fees-container">
                            <Card title="Add Student Miscellaneous Fees">
                                <div className="row g-3">

                                    <div className='col-md-4'>
                                        <label className="form-label fw-bold">Academic Year <span className='text-danger'>*</span></label>
                                        <select
                                            className='form-control'
                                            value={academicYear}
                                            onChange={(e) => { setAcademicYear(e.target.value); setAcademicYearError(''); }}
                                        >
                                            <option value="">Select Academic Year</option>
                                            {last10Years.map((ly) => (
                                                <option key={ly} value={ly}>{ly}</option>
                                            ))}
                                        </select>
                                        {academicYearError && <div className="text-danger small">{academicYearError}</div>}
                                    </div>

                                    <div className='col-md-4'>
                                        <label className="form-label fw-bold">Fees Type <span className="text-danger">*</span></label>
                                        <div className="d-flex align-items-center gap-3">
                                            <div>
                                                <input
                                                    type="radio"
                                                    name="feesType"
                                                    value="year"
                                                    checked={feesType === 'year'}
                                                    onChange={(e) => setFeesType(e.target.value)}
                                                />
                                                <label className="ms-2">Year</label>
                                            </div>
                                            <div style={{ marginLeft: "20px" }}>
                                                <input
                                                    type="radio"
                                                    name="feesType"
                                                    value="semester"
                                                    checked={feesType === 'semester'}
                                                    onChange={(e) => setFeesType(e.target.value)}
                                                />
                                                <label className="ms-2">Semester</label>
                                            </div>
                                        </div>
                                    </div>

                                    {feesType === 'year' ? (
                                        <div className='col-md-4'>
                                            <label className="form-label fw-bold">Year <span className="text-danger">*</span></label>
                                            <select
                                                className="form-control"
                                                value={years}
                                                onChange={(e) => setYears(e.target.value)}
                                            >
                                                <option value="">Select Year</option>
                                                {[1, 2, 3, 4, 5].map((yearOption) => (
                                                    <option key={yearOption} value={yearOption}>{yearOption}</option>
                                                ))}
                                            </select>
                                            {yearsError && <div className="text-danger small">{yearsError}</div>}
                                        </div>
                                    ) : (
                                        <div className='col-md-4'>
                                            <label className="form-label fw-bold">Semester <span className="text-danger">*</span></label>
                                            <select
                                                className="form-control"
                                                value={noOfSemester}
                                                onChange={(e) => setNoOfSemester(e.target.value)}
                                            >
                                                <option value="">Select Semester</option>
                                                {[1, 2, 3, 4, 5, 6, 7, 8].map((semesterOption) => (
                                                    <option key={semesterOption} value={semesterOption}>{semesterOption}</option>
                                                ))}
                                            </select>
                                            {noOfSemesterError && <div className="text-danger small">{noOfSemesterError}</div>}
                                        </div>
                                    )}
                                </div>

                                <div className='row g-3 mt-3'>
                                    <div className='col-md-4'>
                                        <label className="form-label fw-bold">Title <span className="text-danger">*</span></label>
                                        <select
                                            className="form-control"
                                            value={title}
                                            onChange={(e) => {
                                                setTitle(e.target.value);
                                                setTitleError('');
                                            }}
                                        >
                                            <option value="" disabled>Select Title</option>
                                            {
                                                dropDownList.filter(dl => dl.category === 'Misc Fees Title').map((dd, i) => {
                                                    return <option value={dd.title} key={i}>{dd.title}</option>
                                                })
                                            }
                                        </select>
                                        {titleError && <div className="text-danger small">{titleError}</div>}
                                    </div>

                                    <div className='col-md-4'>
                                        <label className="form-label fw-bold">Amount <span className="text-danger">*</span></label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Amount"
                                            min="0"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                        {amountError && <div className="text-danger small">{amountError}</div>}
                                    </div>

                                    <div className='col-md-4'>
                                        <label className="form-label fw-bold">Remarks <span className="text-danger">*</span></label>
                                        <textarea
                                            className="form-control"
                                            placeholder="Enter Remarks"
                                            value={remarks}
                                            onChange={(e) => setRemarks(e.target.value)}
                                        ></textarea>
                                        {remarksError && <div className="text-danger small">{remarksError}</div>}
                                    </div>
                                </div>

                                {/* Submit Button */}
                                <div className="d-flex justify-content-end">
                                    <Button
                                        className="btn btn-sm m-2"
                                        variant="primary"
                                        onClick={addHandler}
                                        style={{ width: "7%", backgroundColor: '#10296C' }}
                                    >
                                        Add
                                    </Button>

                                    {/* {studentFeesList.length > 0 && showDownloadButton && (
                                        <button
                                            className="btn btn-success btn-sm m-2 d-flex align-items-center"
                                            onClick={downloadPDF}
                                            style={{ width: "7%", fontSize: "14px" }}
                                        >
                                            <Feather.Download className="mr-1" size={16} /> PDF
                                        </button>
                                    )} */}
                                </div>
                            </Card>
                        </div>

                        <div className="fees-details-card">
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                                <button
                                    onClick={exportPDF}
                                    style={{
                                        padding: '5px 10px',
                                        marginRight: '10px',
                                        backgroundColor: '#007bff',
                                        color: '#fff',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Feather.Download className="mr-1" size={16} /> PDF
                                </button>
                                <button
                                    onClick={exportCSV}
                                    style={{
                                        padding: '5px 10px',
                                        backgroundColor: '#28a745',
                                        color: '#fff',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Feather.Download className="mr-1" size={16} /> CSV
                                </button>
                            </div>
                            <Card title={`Miscellaneous Details`}>
                                <table className="table text-center">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Academic Year</th>
                                            <th>Term</th>
                                            <th>Fees Title</th>
                                            <th>Amount</th>
                                            <th>Remarks</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentFeesDetails?.length > 0 ? (
                                            studentFeesDetails.map((details, index) => (
                                                <tr key={index + 1}>
                                                    <td>{index + 1}</td>
                                                    <td>{details.academicYear}</td>
                                                    <td>{details.academicPeriod} {details.feesType}</td>
                                                    <td>{details.title}</td>
                                                    <td style={{ textAlign: "right" }}>{details.amount}</td>
                                                    <td>{details.remarks}</td>
                                                    <td>{LocalFormatDate(details.date)}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={7} className="text-center text-muted">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                    </div>
                ) : (
                    <div className="m-2 p-3 text-center text-muted" style={{ height: "500px" }}>
                        No student data available
                    </div>
                )}

                {/* Deletion Confirmation Modal */}
                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this fee record?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>Cancel</Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            </PageContainer>
            <ToastContainer />
        </Main>
    );
};

export default StudentMiscFeesByRegisterNo;